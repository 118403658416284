import { compose, split } from 'lodash/fp';

import * as local from 'util/localization';
import * as LinkService from 'components/Link';
import { ROUTES } from 'components/Routes';

// TODO: pass prioritizeLang = false for signup scenario.
const getValidLocale = (path) =>
  local.getValidLocale({ provideFallback: false }, path);

const getRedirectLocale = compose(
  getValidLocale,
  local.normalizeLocale,
  LinkService.getLocale,
);

const isExistingRoute = (path) => {
  const [, routeRoot] = split('/', LinkService.makeAbsolute(path));
  return LinkService.isValidRoute(routeRoot);
};

const getRedirectPage = (fullPath) => {
  const path = LinkService.removeLocale(fullPath);
  const pathExists = isExistingRoute(path);

  if (pathExists) {
    return path;
  }

  // TODO: when we have a design, we should consider
  //       rendering a proper 404 page instead.
  return `/${ROUTES.OVERVIEW}`;
};

export const getLocalizedRedirect = (path) => {
  const redirectLocale = getRedirectLocale(path);
  const redirectPagePath = getRedirectPage(path);
  return LinkService.addLocale(redirectLocale, redirectPagePath);
};
