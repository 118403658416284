import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';

import * as Service from './NotFoundService';

/**
 * A fallback component for when the application
 * router cannot find a matching route.
 *
 * TODO: get a design for a proper 404 page and show it,
 *       when the route is indeed invalid. Right now
 *       we redirect to the Overview screen (see Service).
 */
const NotFound = ({ uri }) => (
  <Redirect noThrow to={Service.getLocalizedRedirect(uri)} />
);

NotFound.propTypes = {
  /**
   * The uri prop passed down by React Router. This should
   * match the current path in the application, typically
   * a path the router cannot match.
   */
  uri: PropTypes.string,
};

NotFound.defaultProps = {
  uri: '',
};

/**
 * @component
 */
export default NotFound;
