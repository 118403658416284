import URIS from 'constants/uris';
import http from 'services/http';

getMerchantProfileSettings.operation = 'READ';
getMerchantProfileSettings.idFrom = () => 'merchantSettingsId';
export function getMerchantProfileSettings() {
  return http.get(URIS.MERCHANT_PROFILE_SETTINGS);
}

updateMerchantProfileSettings.invalidates = ['getMerchantProfileSettings'];
export function updateMerchantProfileSettings(params) {
  return http.put(URIS.MERCHANT_PROFILE_SETTINGS, params);
}
