import http from 'services/http';
import URIS from 'constants/uris';
import { trim } from 'util/transform';

const CONTEXT = 'dashboard';

// URIS.SHOP_PAYMENTS is a CORS_URIS constant, it doesn't /api prefixed to it
export function generateCheckoutUrl(checkoutHost, checkoutId, token) {
  return `${checkoutHost}${URIS.SHOP_PAYMENTS}/${checkoutId}/payments?otp=${token}`;
}

getOrders.operation = 'READ';
export function getOrders() {
  return http.get(URIS.SHOP_ORDERS).then(({ data }) => data);
}

getOrder.operation = 'READ';
export function getOrder(orderId) {
  return http.get(`${URIS.SHOP_ORDERS}/${orderId}`).then(({ data }) => data);
}

getOrdersWirecard.operation = 'READ';
export function getOrdersWirecard(orderId) {
  return http
    .get(`${URIS.SHOP_ORDERS_WIRECARD}/${orderId}`)
    .then(({ data }) => data);
}

createOrder.operation = 'CREATE';
createOrder.invalidates = ['getOrders', 'getOrder', 'getOrdersWirecard'];
export function createOrder(order) {
  return http.post(URIS.SHOP_ORDERS, trim(order)).then(({ data }) => data);
}

updateOrder.operation = 'UPDATE';
updateOrder.invalidates = ['getOrders', 'getOrder', 'getOrdersWirecard'];
export function updateOrder(order, requestData) {
  // If we update one of the following attributes of a given
  // shipping order, pass the 'requestData' param as a container:
  // products, shipping_address, billing_address,
  // voucher_code, vat_id
  if (requestData) {
    return http
      .put(`${URIS.SHOP_ORDERS}/${order.id}`, requestData)
      .then(({ data }) => data)
      .catch((err) => Promise.reject(err));
  }
  return http
    .put(`${URIS.SHOP_ORDERS}/${order.id}`, trim(order))
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
}

cancelOrder.invalidates = ['getOrders', 'getOrder', 'getOrdersWirecard'];
export function cancelOrder(order, alreadyHasReader = false) {
  const params = { already_have: alreadyHasReader };
  return http
    .delete(`${URIS.SHOP_ORDERS}/${order.id}`, { params })
    .then(({ data }) => data);
}

payByCard.invalidates = ['getOrders', 'getOrder', 'getOrdersWirecard'];
export function payByCard({
  order,
  installments = 0,
  cardData,
  fbuyRefCode,
  referrer,
  partner,
  tags,
  shareId,
}) {
  return http.all([getPaymentUrl(), getOneTimeToken()]).then(([url, token]) => {
    const endpoint = generateCheckoutUrl(url, order.id, token);
    const request = {
      payment_method: 'card',
      installments,
      card: trim(cardData),
      fbuy_ref_code: fbuyRefCode,
      referrer,
      partner,
      tags,
      context: CONTEXT,
      share_id: shareId,
    };
    return http.post(endpoint, request).then(({ data }) => data);
  });
}

payByWirecard.invalidates = ['getOrders', 'getOrder', 'getOrdersWirecard'];
export function payByWirecard({
  order,
  paymentMethod,
  locale,
  fbuyRefCode,
  referrer,
  partner,
  tags,
  shareId,
}) {
  const req = {
    payment_method: paymentMethod,
    locale,
    fbuy_ref_code: fbuyRefCode,
    referrer,
    partner,
    tags,
    context: CONTEXT,
    share_id: shareId,
  };
  return http
    .post(`${URIS.SHOP_ORDERS}/${order.id}/payments`, req)
    .then(({ data }) => data)
    .catch((err) => http.reject(err));
}

payByOther.invalidates = ['getOrders', 'getOrder', 'getOrdersWirecard'];
export function payByOther({
  order,
  paymentMethod,
  fbuyRefCode,
  referrer,
  partner,
  tags,
  shareId,
}) {
  const req = {
    payment_method: paymentMethod,
    fbuy_ref_code: fbuyRefCode,
    referrer,
    partner,
    tags,
    context: CONTEXT,
    share_id: shareId,
  };
  return http
    .post(`${URIS.SHOP_ORDERS}/${order.id}/payments`, req)
    .then(({ data }) => data);
}

getBoletoBarcode.operation = 'READ';
export function getBoletoBarcode({ id }) {
  return http
    .get(`${URIS.SHOP_PROCESS_PAYMENTS}/boleto?order_id=${id}`, {
      headers: {
        Accept: 'barcode',
      },
    })
    .then(({ data }) => data);
}

applyVoucher.operation = 'NO_OPERATION';
applyVoucher.invalidates = ['getOrders', 'getOrder', 'getOrdersWirecard'];
export function applyVoucher(orderId, voucherCode) {
  return http.put(`${URIS.SHOP_ORDERS}/${orderId}`, {
    voucher_code: voucherCode,
  });
}

function getPaymentUrl() {
  return http
    .get(URIS.SESSION_INFO)
    .then(({ data: { checkout_host: paymentUrl } }) => paymentUrl);
}

function getOneTimeToken() {
  return http
    .post(
      URIS.ONE_TIME_TOKENS,
      { dummy: 1 },
      {
        headers: { 'x-sumup-allow-origin': 'payment-gateway' },
      },
    )
    .then(({ data: { otpToken: token } }) => token);
}

export function updateOrderPaymentStatus(orderId, params) {
  return http
    .put(`${URIS.SHOP_ORDERS}/${orderId}/update-payment`, trim(params))
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));
}

export function createGuestOrder(username, order) {
  return http
    .post(URIS.SHOP_GUEST_ORDERS, { ...order, username })
    .then(({ data }) => data);
}
