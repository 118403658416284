import URIS from 'constants/uris';
import http from 'services/http';

getDynamicDescriptor.operation = 'READ';
getDynamicDescriptor.idFrom = () => 'getDynamicDescriptorId';
export function getDynamicDescriptor() {
  return http.get(URIS.DYNAMIC_DESCRIPTOR);
}

updateDynamicDescriptor.invalidates = ['getDynamicDescriptor'];
export function updateDynamicDescriptor(params) {
  return http.put(URIS.DYNAMIC_DESCRIPTOR, params);
}

deleteDynamicDescriptor.invalidates = ['getDynamicDescriptor'];
export function deleteDynamicDescriptor() {
  return http.delete(URIS.DYNAMIC_DESCRIPTOR);
}
