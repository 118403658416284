import URIS from 'constants/uris';
import http from 'services/http';

createProjectCredentials.invalidates = ['getClientProjects'];
export function createProjectCredentials({ projectId, credentials }) {
  return http
    .post(
      `${URIS.CLIENT_PROJECTS}/${projectId}/client-credentials`,
      credentials,
    )
    .then(({ data }) => data);
}

getClientProjects.operation = 'READ';
export function getClientProjects() {
  return http.get(URIS.CLIENT_PROJECTS).then(({ data }) => data);
}

updateClientProject.invalidates = ['getClientProjects'];
export function updateClientProject({ project }) {
  return http
    .put(`${URIS.CLIENT_PROJECTS}/${project.id}`, project)
    .then(({ data }) => data);
}

createClientProject.invalidates = ['getClientProjects'];
export function createClientProject({ project }) {
  return http.post(URIS.CLIENT_PROJECTS, project).then(({ data }) => data);
}
