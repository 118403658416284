import URIS from 'constants/uris';
import http from 'services/http';

getDocuments.operation = 'READ';
export function getDocuments() {
  return http.get(URIS.ACCOUNT_DOCUMENTS).then(({ data }) => data);
}

export function uploadDocuments(params) {
  return http.post(URIS.ACCOUNT_DOCUMENTS, params);
}

confirmDocumentsUpload.invalidates = ['getDocuments'];
export function confirmDocumentsUpload(params) {
  return http.put(URIS.ACCOUNT_DOCUMENTS, params);
}
