import { keys } from 'lodash/fp';

const CHAT_ID = 'LiveAgent';
const SCRIPT_INJECTOR_CONFIG = {
  src: {
    production: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    staging:
      'https://sumup--quality.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    development:
      'https://sumup--quality.my.salesforce.com/embeddedservice/5.0/esw.min.js',
  },
  async: true,
};

const LIVE_AGENT_BUTTON_COUNTRY = {
  production: {
    NL: '573570000008Qnb',
    FR: '573570000008QnW',
    GB: '573D0000000CcPv',
    IT: '57357000000blMv',
    DE: '5732p000000Csmd',
    AT: '57357000000blN0',
    IE: '573D0000000CcPv',
    CH: '57357000000blMv',
    BE: '573570000008QnW',
    PL: '573D0000000CcQ0',
    BG: '57357000000TOcR',
    FI: '57357000000Gptv',
  },
  staging: {
    NL: '573570000008Qnb',
    FR: '573570000008QnW',
    GB: '573D0000000CcPv',
    IT: '57357000000blMv',
    DE: '5731q0000008OzK',
    AT: '57357000000blN0',
    IE: '573D0000000CcPv',
    CH: '57357000000blMv',
    BE: '573570000008QnW',
    PL: '573D0000000CcQ0',
    BG: '57357000000TOcR',
    FI: '57357000000Gptv',
  },
  development: {
    NL: '573570000008Qnb',
    FR: '573570000008QnW',
    GB: '573D0000000CcPv',
    IT: '57357000000blMv',
    DE: '5731q0000008OzK',
    AT: '57357000000blN0',
    IE: '573D0000000CcPv',
    CH: '57357000000blMv',
    BE: '573570000008QnW',
    PL: '573D0000000CcQ0',
    BG: '57357000000TOcR',
    FI: '57357000000Gptv',
  },
};

const LIVE_AGENT_BUTTON_LOCALES = {
  production: {
    'nl-NL': '573570000008Qnb',
    'nl-BE': '573570000008Qnb',
    'fr-FR': '573570000008QnW',
    'fr-CH': '573570000008QnW',
    'fr-BE': '573570000008QnW',
    'en-GB': '573D0000000CcPv',
    'en-IE': '573D0000000CcPv',
    'it-IT': '57357000000blMv',
    'it-CH': '57357000000blMv',
    'de-DE': '5732p000000Csmd',
    'de-AT': '57357000000blN0',
    'de-CH': '57357000000blN0',
    'pl-PL': '573D0000000CcQ0',
    'fi-FI': '57357000000Gptv',
  },
  staging: {
    'nl-NL': '573570000008Qnb',
    'nl-BE': '573570000008Qnb',
    'fr-FR': '573570000008QnW',
    'fr-CH': '573570000008QnW',
    'fr-BE': '573570000008QnW',
    'en-GB': '573D0000000CcPv',
    'en-IE': '573D0000000CcPv',
    'it-IT': '57357000000blMv',
    'it-CH': '57357000000blMv',
    'de-DE': '5731q0000008OzK',
    'de-AT': '57357000000blN0',
    'de-CH': '57357000000blN0',
    'pl-PL': '573D0000000CcQ0',
    'fi-FI': '57357000000Gptv',
  },
  development: {
    'nl-NL': '573570000008Qnb',
    'nl-BE': '573570000008Qnb',
    'fr-FR': '573570000008QnW',
    'fr-CH': '573570000008QnW',
    'fr-BE': '573570000008QnW',
    'en-GB': '573D0000000CcPv',
    'en-IE': '573D0000000CcPv',
    'it-IT': '57357000000blMv',
    'it-CH': '57357000000blMv',
    'de-DE': '5731q0000008OzK',
    'de-AT': '57357000000blN0',
    'de-CH': '57357000000blN0',
    'pl-PL': '573D0000000CcQ0',
    'fi-FI': '57357000000Gptv',
  },
};

const SUPPORTED_COUNTRIES = (env) => keys(LIVE_AGENT_BUTTON_COUNTRY[env]);

const DEFAULT_LIVE_AGENT_BUTTON_COUNTRY = (env) =>
  LIVE_AGENT_BUTTON_COUNTRY[env].GB;
const AVATAR_URL =
  'https://sumup.my.salesforce.com/servlet/servlet.FileDownload?file=015570000019uRz';
const WAITING_STATE_BACKGROUND_IMG_URL =
  'https://sumup.my.salesforce.com/servlet/servlet.FileDownload?file=015570000019uRu';
const SMALL_COMPANY_LOGO_IMG_URL =
  'https://sumup.my.salesforce.com/servlet/servlet.FileDownload?file=015570000019uRp';
const SALESFORCE_URL = {
  production: 'https://sumup.my.salesforce.com',
  staging: 'https://sumup--quality.my.salesforce.com',
  development: 'https://sumup--quality.my.salesforce.com',
};
const SALESFORCE_CHAT_URL = {
  production: 'https://sumup.secure.force.com/Chat',
  staging: 'https://quality-sumup.cs107.force.com/Chat',
  development: 'https://quality-sumup.cs107.force.com/Chat',
};
const ORIG_ID = {
  production: '00DD0000000pnkQ',
  staging: '00D1q0000000MmF',
  development: '00D1q0000000MmF',
}; // ORIG_ID
const LIVE_AGENT_CONTENT_URL = {
  production: 'https://c.la1-c2-fra.salesforceliveagent.com/content',
  staging: 'https://c.la1-c1cs-fra.salesforceliveagent.com/content',
  development: 'https://c.la1-c1cs-fra.salesforceliveagent.com/content',
};
const DEPLOYMENT_ID = '572D00000008Q3X';
const LIVE_AGENT_URL = {
  production: 'https://d.la1-c2-fra.salesforceliveagent.com/chat',
  staging: 'https://d.la1-c1cs-fra.salesforceliveagent.com/chat',
  development: 'https://d.la1-c1cs-fra.salesforceliveagent.com/chat',
};
const LIVE_AGENT_DEV_NAME =
  'EmbeddedServiceLiveAgent_Parent04I570000000006EAA_161715453d0';

export const getLiveAgentConfig = (env) => ({
  CHAT_ID,
  SCRIPT_INJECTOR_CONFIG: {
    ...SCRIPT_INJECTOR_CONFIG,
    src: SCRIPT_INJECTOR_CONFIG.src[env],
  },
  LIVE_AGENT_BUTTON_COUNTRY: LIVE_AGENT_BUTTON_COUNTRY[env],
  LIVE_AGENT_BUTTON_LOCALES: LIVE_AGENT_BUTTON_LOCALES[env],
  SUPPORTED_COUNTRIES: SUPPORTED_COUNTRIES(env),
  DEFAULT_LIVE_AGENT_BUTTON_COUNTRY: DEFAULT_LIVE_AGENT_BUTTON_COUNTRY(env),
  AVATAR_URL,
  WAITING_STATE_BACKGROUND_IMG_URL,
  SMALL_COMPANY_LOGO_IMG_URL,
  SALESFORCE_URL: SALESFORCE_URL[env],
  SALESFORCE_CHAT_URL: SALESFORCE_CHAT_URL[env],
  ORIG_ID: ORIG_ID[env],
  LIVE_AGENT_CONTENT_URL: LIVE_AGENT_CONTENT_URL[env],
  DEPLOYMENT_ID,
  LIVE_AGENT_URL: LIVE_AGENT_URL[env],
  LIVE_AGENT_DEV_NAME,
});

export const SELECTORS_TO_BE_HIDDEN = [
  '.footerMenuWrapper',
  '.embeddedServiceLiveAgentStateChatUnreadMessageBanner',
];
