import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';

import * as AuthService from 'services/auth';

import {
  getUserObservable,
  getMerchantCode,
  getUserCountry,
  setGlobalMerchantCode,
  // TODO: enable this once timeouts are back to normal.
  // setSlowLoadWarningTimeout
} from './UserProviderService';

export const UserContext = createContext({});

export default class UserProvider extends Component {
  static propTypes = {
    value: PropTypes.shape({
      me: PropTypes.object,
      accountState: PropTypes.object,
      settings: PropTypes.object,
      permissions: PropTypes.object,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    locale: PropTypes.string,
    changeLocale: PropTypes.func.isRequired,
  };

  static defaultProps = {
    location: {},
  };

  state = {
    user: null,
    fetchError: null,
  };

  componentDidMount() {
    if (
      AuthService.isProtectedRoute(this.props.location.pathname) &&
      !this.subscription
    ) {
      this.subscription = this.createSubscription();
    }
  }

  componentDidUpdate(prevProps) {
    const isProtectedRoute = AuthService.isProtectedRoute(
      this.props.location.pathname,
    );

    if (isProtectedRoute && !this.subscription) {
      this.subscription = this.createSubscription();
    }

    const isDifferentLocation =
      prevProps.location.pathname !== this.props.location.pathname;
    const hasError = !!this.state.fetchError;

    if (!isProtectedRoute && this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;

      this.setState({
        user: null,
      });
    }

    if (isDifferentLocation && hasError && this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = this.createSubscription();
    }
  }

  updateUserLocale(user) {
    if (!user) {
      return;
    }

    const { locale, changeLocale } = this.props;

    if (user) {
      const country = getUserCountry(user);

      if (country === 'BR' && locale !== 'pt-BR') {
        changeLocale('pt-BR');
      }

      if (country === 'CL' && locale !== 'es-CL') {
        changeLocale('es-CL');
      }
    }
  }

  createSubscription() {
    const boundSetState = this.setState.bind(this);
    const boundUpdateUserLocale = this.updateUserLocale.bind(this);

    const { value } = this.props;

    if (value) {
      boundSetState({ user: value });
      boundUpdateUserLocale(value);

      // Set the merchant code for already logged in users (EG page refreshed)
      setGlobalMerchantCode(getMerchantCode(value));

      // TODO: replace the unsubscribe with a cleaner way of checking whether
      // the provider has the state
      return {
        unsubscribe: () => {},
      };
    }

    // TODO: enable this once timeouts are back to normal.
    // this.cancelSlowLoadWarning = setSlowLoadWarningTimeout();
    return getUserObservable().subscribe({
      next(user) {
        if (this.cancelSlowLoadWarning !== undefined) {
          this.cancelSlowLoadWarning();
          delete this.cancelSlowLoadWarning;
        }

        boundSetState({ user, fetchError: null });
        boundUpdateUserLocale(user);
        setGlobalMerchantCode(getMerchantCode(user));
      },
      error(err) {
        boundSetState({ fetchError: err });
      },
    });
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  render() {
    const { children, location } = this.props;
    const { user } = this.state;

    if (AuthService.isProtectedRoute(location.pathname) && isEmpty(user)) {
      return null;
    }

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  }
}

export const UserConsumer = UserContext.Consumer;
