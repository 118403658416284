import withI18n from 'components/addI18n';

import UserProvider from './UserProvider';

export { UserContext } from './UserProvider';
export { UserConsumer } from './UserProvider';

export * from './UserProviderService';

export default withI18n(UserProvider);
