import http from 'services/http';
import URIS from 'constants/uris';

getFeatureToggleMany.operation = 'READ';
getFeatureToggleMany.idFrom = 'ARGS';
export function getFeatureToggleMany(names, country = '', merchantCode = '') {
  const query = names.map((name) => `names[]=${name}`).join('&');
  let url = `${URIS.FEATURE_TOGGLES}?${query}&compact=true`;
  if (country) {
    url += `&country=${country}`;
  }

  if (merchantCode) {
    url += `&merchant_code=${merchantCode}`;
  }

  return http.get(url).then(({ data }) => data);
}
