import {
  __,
  compose,
  curry,
  defaultTo,
  first,
  find,
  get,
  identity,
  includes,
  keys,
  last,
  reduce,
  some,
  split,
  toUpper,
  toLower,
} from 'lodash/fp';

import {
  defaultLanguages,
  defaultCountries,
  allCountries,
  supportedLocales,
  SINGLE_LOCALE_COUNTRIES,
  DEFAULT_LOCALE,
} from 'constants/localization';

const LOCALE_REGEX = /^[a-z]{2}-[A-Z]{2}$/;

const or = curry((predicate, a, b, val) => (predicate(val) ? a(val) : b(val)));

export const isSupportedLanguage = compose(
  Boolean,
  get(__, defaultLanguages),
  toLower,
);

export const isSupportedCountry = compose(includes(__, allCountries), toUpper);

/**
 * Limited countries are countries with only one language available.
 * Such as en-us, cl-cl and pt-br
 */
export const isSingleLocaleCountry = (country) =>
  includes(country, SINGLE_LOCALE_COUNTRIES);

export const getDefaultLanguageLocale = compose(
  defaultTo(''),
  get(__, defaultLanguages),
  toLower,
);

export const getDefaultCountryLocale = compose(
  defaultTo(''),
  get(__, defaultCountries),
  toUpper,
);

const getCountryFromIsoLocale = compose(toUpper, last, split('-'));
const getCountryFromLanguage = compose(
  getCountryFromIsoLocale,
  getDefaultLanguageLocale,
);

export const getCountryFromLocale = compose(
  or(isSupportedCountry, identity, getCountryFromLanguage),
  getCountryFromIsoLocale,
);

const getLanguageFromIsoLocale = compose(toLower, first, split('-'));
const getLanguageFromCountry = compose(
  getLanguageFromIsoLocale,
  getDefaultCountryLocale,
);

export const getLanguageFromLocale = compose(
  or(isSupportedLanguage, identity, getLanguageFromCountry),
  getLanguageFromIsoLocale,
);

export const normalizeLocale = compose(
  ([lang, country]) =>
    lang && country ? `${toLower(lang)}-${toUpper(country)}` : '',
  split('-'),
);

export const composeLocale = ({ country, language } = {}) =>
  country && language && `${toLower(language)}-${toUpper(country)}`;

export const isSupportedLocale = compose(
  includes(__, supportedLocales),
  normalizeLocale,
);

export const hasLocalePattern = (str = '') => LOCALE_REGEX.test(str);

const getOnlyKey = compose(first, keys);
const findTargetLocale = find(some(identity));

export const getValidLocale = curry(
  (
    { strictLocales = false, prioritizeLang = true, provideFallback = true },
    locale,
  ) => {
    const normalizedLocale = normalizeLocale(locale);

    if (isSupportedLocale(normalizedLocale)) {
      return normalizedLocale;
    }

    const country = getCountryFromLocale(locale);
    const language = getLanguageFromLocale(locale);
    const isSupportedCtry = isSupportedCountry(country);
    const isSupportedLang = isSupportedLanguage(language);
    const isSingleLocale = isSingleLocaleCountry(country);

    if (
      normalizedLocale &&
      !strictLocales &&
      !isSingleLocale &&
      isSupportedCtry &&
      isSupportedLang
    ) {
      return normalizedLocale;
    }

    // Need to build a custom locale
    const fixedTargetLocales = reduce(
      (acc, c) => [...acc, { [getDefaultCountryLocale(c)]: country === c }],
      [],
      SINGLE_LOCALE_COUNTRIES,
    );

    const targetLocales = [
      ...fixedTargetLocales,
      {
        [getDefaultLanguageLocale(language)]:
          isSupportedLang && (prioritizeLang || !isSupportedCtry),
      },
      {
        [getDefaultCountryLocale(country)]: isSupportedCtry,
      },
      { [DEFAULT_LOCALE]: provideFallback },
    ];

    return compose(defaultTo(''), getOnlyKey, findTargetLocale)(targetLocales);
  },
);
