import api from 'api';
import { keys, includes, filter } from 'lodash/fp';
import { nonEuCountries } from 'constants/localization.js';

export const hasAcceptedRequiredRules = (rules) =>
  rules.reduce(
    (memo, { is_required: required, is_accepted: accepted }) =>
      memo || (required && !accepted),
    false,
  );

export const shouldShowPage = hasAcceptedRequiredRules;

export const isGdprRoute = (route = window.location.pathname) =>
  route.includes('/gdpr');

export const mapFormValuesToRequest = (data) =>
  keys(data).map((key) => ({
    gdpr_agreement: key,
    is_accepted: data[key],
  }));

const sortByRequiredFirst = (arr) =>
  arr.sort((a, b) => {
    const bBeforeA = !a.is_required && b.is_required;
    return bBeforeA ? 1 : -1;
  });
export const formatConsentRequest = (consents) => {
  const result = consents.map((item) => ({
    gdpr_agreement: item.gdpr_agreement.gdpr_agreement,
    is_accepted: item.is_accepted,
    is_required: item.gdpr_agreement.is_required,
  }));
  return sortByRequiredFirst(result);
};

export const formatRulesRequest = (rules) =>
  sortByRequiredFirst(rules).map((item) => {
    item.is_accepted = false;
    return item;
  });

export const getConsents = () =>
  api.gdprConsents.getConsents().then(formatConsentRequest);

export const getRules = () => api.gdprRules.getRules().then(formatRulesRequest);

export const saveConsents = (params) =>
  api.gdprConsents.updateConsents({ gdpr_agreements: params });

export const toInitialFormValues = (agreements) =>
  agreements.reduce((memo, { gdpr_agreement: key, is_accepted: value }) => {
    memo[key] = value;
    return memo;
  }, {});

export const createFormRules = (agreements) =>
  agreements.reduce((memo, { gdpr_agreement: name, is_required: required }) => {
    memo[name] = {
      rules: {
        gdprValidate: (val) => (required ? required && val : true),
      },
    };
    return memo;
  }, {});

export const extractConsents = (agreements, formData) =>
  agreements.map(({ gdpr_agreement: agreement }) => ({
    gdpr_agreement: agreement,
    is_accepted: !!formData[agreement],
  }));

export const hasGDPR = (country) => !includes(country, nonEuCountries);

const COUNTRY_RULES = {
  CL: {
    privacy_policy_and_terms_conditions: true,
    marketing_information: false,
  },
  DEFAULT: {
    privacy_policy_and_terms_conditions: true,
    marketing_information: true,
  },
};

export const filterByCountry = (country) =>
  filter(
    (r) => (COUNTRY_RULES[country] || COUNTRY_RULES.DEFAULT)[r.gdpr_agreement],
  );

export const shouldShowGDPR = () =>
  getConsents()
    .then((agreements) => shouldShowPage(agreements))
    .catch(() =>
      getRules()
        .then((agreements) => shouldShowPage(agreements))
        .catch((err) => Promise.reject(err)),
    );

export const shouldShowGdprPage = () =>
  Promise.all([shouldShowGDPR(), api.me.getMe()]).then(
    ([merchantAcceptedGdpr, user]) => {
      if (!user.data) {
        return Promise.reject();
      }

      return (
        hasGDPR(user.data.merchant_profile.country) && merchantAcceptedGdpr
      );
    },
  );

export const isGdprRequired = () =>
  api.me
    .getMe()
    .then(({ data }) => {
      if (!data) {
        return Promise.reject();
      }
      return api.featureToggles
        .getFeatureToggle(
          'gdpr_enforced',
          data.merchant_profile.country,
          data.merchant_profile.merchant_code,
        )
        .then(({ active }) => active);
    })
    .catch(false); // So a 404 feature toggle doesn't cause a promise chain to fail
