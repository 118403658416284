import {
  __,
  findKey,
  includes,
  find,
  curry,
  compose,
  identity,
  isEmpty,
  replace as replaceString,
  startsWith,
  toLower,
  omit,
} from 'lodash/fp';
import querystring from 'query-string';

// FIXME: Replace this when we either move to webpack4 or refactor Employees page
// import { ROUTES } from 'components/Routes';
import { getValidLocale } from 'util/localization';
import ROUTES from 'components/Routes/RoutesConstants';

// TODO: the path localization functions should move to util/localization.js

/**
 * This RegEx supports various locale formats. Locales can be either two letters,
 * in which case they need to be followed by a slash to separete them from regular
 * routes, or valid ISO strings, in which case the trailing slash is optional.
 */
// eslint-disable-next-line
const PATH_LOCALE_REGEX = /^\/?((?:[a-z]{2}(?:-[a-z]{2})?\/)|(?:[a-z]{2}-[a-z]{2}))/i;
const QUERY_REGEX = /\?.*$/;

export const makeAbsolute = (path) =>
  startsWith('/', path) ? path : `/${path}`;

export const makeRelative = replaceString(/^\//, '');

export const createGetLocale = curry(
  ({ regex = PATH_LOCALE_REGEX, validateLocale = true }, path) => {
    const [, locale] = (path || '').match(regex) || [];

    return compose(
      toLower,
      validateLocale ? getValidLocale({ provideFallback: false }) : identity,
      replaceString(/\//g, ''),
    )(locale);
  },
);

export const getLocale = createGetLocale({});

export const createRemoveLocale = curry(({ regex = PATH_LOCALE_REGEX }, path) =>
  makeAbsolute(path.replace(regex, '')),
);

export const removeLocale = createRemoveLocale({});

export const addLocale = curry((locale, path) => {
  if (!locale) {
    return path;
  }

  const currentLocale = getLocale(path);
  const shouldReplace = currentLocale;

  return shouldReplace
    ? path.replace(PATH_LOCALE_REGEX, `/${locale.toLowerCase()}/`)
    : `/${locale.toLowerCase()}${makeAbsolute(path)}`;
});

const getQueryFromString = (string) => {
  const [query] = (string || '').match(QUERY_REGEX) || [];

  return querystring.parse(query);
};

const getNormalizedQuery = (query) =>
  isEmpty(query) ? querystring.parse(window.location.search) : query;

const mergeQueryParams = (params, path) => ({
  ...getNormalizedQuery(params),
  ...getQueryFromString(path),
});

export const addQueryString = curry((params, path) => {
  const hasQuery = !isEmpty(params);

  return hasQuery
    ? `${path.replace(QUERY_REGEX, '')}?${querystring.stringify(params)}`
    : path;
});

export const mapToLegacyRoute = (to, legacyStates) => {
  const normalizedTarget = to.replace(QUERY_REGEX, '');

  const isAuthRoute =
    includes('logout', normalizedTarget) || includes('login', normalizedTarget);

  const legacyState = find(
    ({ url, name }) =>
      startsWith(normalizedTarget.replace(/^\//, ''), url.replace(/^\//, '')) &&
      (startsWith('i18n.main', name) || isAuthRoute),
  )(legacyStates);

  return legacyState ? legacyState.name : undefined;
};

export const isValidRoute = (route) => !!findKey(includes(__, route), ROUTES);

/**
 * Identifies whether or not provided target will require switching between apps
 */
export const needsToSwitchApps = (target, legacyStates = []) => {
  const isReactAppTarget = isValidRoute(target);

  /**
   * This line is valid since react app doesn't contain legacyStates.
   * (It is a ui router arg)
   */
  const isReactApp = !legacyStates.length;

  return (isReactApp && !isReactAppTarget) || (!isReactApp && isReactAppTarget);
};

/**
 * Helper to set the proper order or arguments for react vs angular navigate fns
 */
export const normalizeNavigateArgs = (
  isReactAppTarget,
  { state = false, replace = false } = {},
) => {
  if (isEmpty(state) && !replace) {
    return [];
  }

  return isReactAppTarget ? [{ state, replace }] : [state, { replace }];
};

export const createCompatibilityNavigate = (navigateFn, legacyStates = []) => (
  target,
  { state, replace, preserveQuery = false, omitFromQuery = [] } = {},
) => {
  const locale = getLocale(target) || getLocale(window.location.pathname);
  const query = omit(omitFromQuery, mergeQueryParams(state, target));
  const isReactAppTarget = isValidRoute(target);
  const normalizedTarget = compose(
    preserveQuery ? addQueryString(query) : identity,
    addLocale(locale),
  )(target);

  /**
   * Tries to map the target to react/angular route. If it fails to do so,
   * it will return undefined, which means we need to bail out of the in app
   * navigation and use a location assign
   */
  const mappedTarget = isReactAppTarget
    ? normalizedTarget
    : mapToLegacyRoute(target, legacyStates);

  /**
   * If we identify the provided target (path) isn't part of the current app,
   * Or if we couldn't map the target to a route, we execute the assign fn,
   * which will trigger the other app.
   */
  if (needsToSwitchApps(target, legacyStates) || !mappedTarget) {
    window.location.assign(normalizedTarget);
  } else {
    const navigateArgs = normalizeNavigateArgs(isReactAppTarget, {
      state: query,
      replace,
    });

    navigateFn(mappedTarget, ...navigateArgs);
  }
};
