import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash/fp';
import AppContext from 'components/AppContext';

const Link = ({
  as,
  to,
  state,
  replace,
  preserveQuery,
  children,
  onClick,
  ...rest
}) => {
  const props = omit(
    ['translate', 'isLoadingTranslations', 'changeLocale'],
    rest,
  );
  const A = as || 'a';
  return (
    <AppContext.Consumer>
      {({ navigate }) => (
        <A
          {...props}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();

            if (onClick) {
              onClick(e);
            }

            navigate(to, { state, preserveQuery, replace });
          }}
        >
          {children}
        </A>
      )}
    </AppContext.Consumer>
  );
};

Link.propTypes = {
  as: PropTypes.element,
  to: PropTypes.string.isRequired,
  state: PropTypes.object,
  replace: PropTypes.bool,
  preserveQuery: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Link;
