import React, { Fragment, Suspense, lazy } from 'react';
import ReactDom from 'react-dom';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { ThemeProvider } from 'emotion-theming';
import { I18nextProvider } from 'react-i18next';
import { Location, navigate } from '@reach/router';
import {
  ModalProvider,
  BaseStyles,
  SidebarContextProvider,
} from '@sumup/circuit-ui';
import { light } from '@sumup/design-tokens';
import { omit } from 'lodash/fp';
import qs from 'query-string';

import Routes from 'components/Routes';
import LocaleRedirect from 'components/LocaleRedirect';
import GdprRedirect from 'components/GdprRedirect';
import PageviewHandler from 'components/PageviewHandler';
import AppContext from 'components/AppContext';
import UserProvider from 'components/UserProvider';
import NotificationProvider from 'components/NotificationProvider';
import GlobalStyles from 'components/GlobalStyles';
import LayoutContainer from 'components/LayoutContainer';
import PaddingContainer from 'components/PaddingContainer';
import WebviewHandler from 'components/WebviewHandler';
import Collector from 'components/Collector';
import LiveAgent from 'components/LiveAgent';
import { CLIENT_INFO } from 'util/user-agent';
import { createCompatibilityNavigate, getLocale } from 'components/Link';
import { createI18n } from 'services/i18n';

import {
  hasSession,
  getOtpToken,
  createOtpSession,
  createUnauthorizedSession,
  isProtectedRoute,
} from 'services/auth';

import logger from 'services/logger';
import sessionTimer from 'services/session-timer';
import { optimizelyClient, getOptimizelyUser } from 'services/optimizely';

const GlobalNotifications = lazy(() =>
  import(
    /* webpackChunkName: "notifications" */ 'components/GlobalNotifications'
  ),
);

const SideNav = lazy(() =>
  import(/* webpackChunkName: "sidenav" */ 'components/SideNav'),
);

const { isWebView } = CLIENT_INFO;

const App = () => (
  <AppContext.Provider
    value={{ navigate: createCompatibilityNavigate(navigate) }}
  >
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={getOptimizelyUser()}
    >
      <ThemeProvider theme={light}>
        <Fragment>
          <BaseStyles />
          <WebviewHandler>
            <Location>
              {({ location }) => (
                <I18nextProvider
                  i18n={createI18n(getLocale(location.pathname))}
                >
                  <UserProvider location={location}>
                    <ModalProvider>
                      <Collector pathname={location.pathname}>
                        <NotificationProvider>
                          <SidebarContextProvider>
                            <PageviewHandler href={location.href} />
                            {!isWebView && <LiveAgent />}
                            <GlobalStyles />
                            <LayoutContainer>
                              {isProtectedRoute(location.pathname) && (
                                <Suspense fallback>
                                  <SideNav pathname={location.pathname} />
                                </Suspense>
                              )}
                              <LocaleRedirect pathname={location.pathname}>
                                <GdprRedirect
                                  pathname={location.pathname}
                                  location={location}
                                >
                                  <LayoutContainer vertical>
                                    <PaddingContainer
                                      pathname={location.pathname}
                                    />

                                    {isProtectedRoute(location.pathname) && (
                                      <Suspense fallback>
                                        <GlobalNotifications
                                          pathname={location.pathname}
                                        />
                                      </Suspense>
                                    )}
                                    <Routes />
                                  </LayoutContainer>
                                </GdprRedirect>
                              </LocaleRedirect>
                            </LayoutContainer>
                          </SidebarContextProvider>
                        </NotificationProvider>
                      </Collector>
                    </ModalProvider>
                  </UserProvider>
                </I18nextProvider>
              )}
            </Location>
          </WebviewHandler>
        </Fragment>
      </ThemeProvider>
    </OptimizelyProvider>
  </AppContext.Provider>
);

const renderApp = () => {
  sessionTimer.init(); // init session timer after the session has been created
  ReactDom.render(<App />, document.getElementById('root'));
};

/**
 * Previously the backend was creating an unauthorized session for the static
 * files. But since now we serve the assets from nginx directly, we are
 * delegating for the frontend to request a token upfront if it doesn't have any
 */
(() => {
  logger.init();

  const otpToken = getOtpToken(window.location.search);
  const session = hasSession();

  if (otpToken) {
    createOtpSession(otpToken)
      .catch(logger.error)
      .then(renderApp);

    const values = omit(['c'], qs.parse(window.location.search));
    const query = qs.stringify(values);

    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}${query ? `?${query}` : ''}`,
    );
  } else if (!session) {
    createUnauthorizedSession()
      .catch(logger.error)
      .then(renderApp);
  } else {
    renderApp();
  }
})();
