import { findKey } from 'lodash/fp';

function matchDictKeyToString(dict, str) {
  return findKey((regex) => regex.test(str), dict);
}

function detectPlatform(userAgentString) {
  const PLATFORM_DICT = {
    android: /Android/i,
    ios: /iPhone|iPad|iPod/i,
    mac: /Mac/,
    win: /Win/,
    unix: /X11/,
    linux: /Linux/,
  };

  return matchDictKeyToString(PLATFORM_DICT, userAgentString);
}

function detectBrowser(platform, userAgentString) {
  const BROWSER_DICT = {
    edge: /Edge/,
    ie: /(MSIE)|(Trident)/,
    opera: /Opera/,
    firefox: /Firefox/,
    chrome: /Chrome/,
  };

  const browser = matchDictKeyToString(BROWSER_DICT, userAgentString);

  if (platform === 'ios' && !browser) {
    return 'safari';
  }

  return browser;
}

export function isRunningInApp() {
  // Detects the Webview based on the in_app query param as per this ticket:
  // https://sumupteam.atlassian.net/browse/DAS-361
  const { search } = window.location;
  return search.indexOf('in_app') > -1;
}

// FIXME: we can deprecate this once nothing else uses it and
//        use the constant below, instead?
export function getUserAgent() {
  // Obtain user agent
  const userAgentString = window.navigator.appVersion;

  // Platform components
  const platform = detectPlatform(userAgentString);
  const browser = detectBrowser(platform, userAgentString);
  const isMobile = platform === 'ios' || platform === 'android';
  const isWebView = isRunningInApp();
  const isInternetExplorer = browser === 'ie';

  return {
    platform,
    browser,
    isWebView,
    isMobile,
    isInternetExplorer,
  };
}

export const CLIENT_INFO = getUserAgent();

export function isRetina() {
  return window.devicePixelRatio > 1;
}
