import URIS from 'constants/uris';
import http from 'services/http';

/**
 * @desc Fetches POS merchant data, including fiscalization.
 *
 * @return {Object} The data returned by the API.
 *
 */
export function getPosMerchant() {
  return http.get(URIS.POS_API).then(({ data }) => data);
}

/**
 * @desc Activates fiscalization with the given merchant business details.
 *
 * @param {Object} params The parameters of the activateFiscaliztion call.
 * @param {Object} params.business_details - The merchant's fiscalization business details.
 * @param {string} [params.business_details.economic_identification_number] - The merchant's economic identification number.
 * @param {string} params.business_details.company_name - The merchant's company name.
 * @param {string} params.business_details.tax_id - The merchant's tax ID.
 * @param {string} params.business_details.vat_id - The merchant's VAT ID.
 * @param {Object} params.business_details.address - The merchant's business address.
 * @param {string} params.business_details.address.address_line1 - The merchant's business address line 1.
 * @param {string} [params.business_details.address.address_line2] - The merchant's business address line 2.
 * @param {string} params.business_details.address.zip_code - The merchant's business zip code.
 * @param {string} params.business_details.address.city - The merchant's business city.
 * @param {string} params.business_details.address.country_code - The merchant's business country code.
 *
 * @return {Object} The data returned by the API.
 *
 */
export function activateFiscalization(params) {
  return http
    .post(`${URIS.POS_API}/fiscalization`, params)
    .then(({ data }) => data);
}
