import http from 'services/http';
import URIS from '../constants/uris';

getConsents.operation = 'READ';
export function getConsents() {
  return http.get(URIS.GDPR_ACCOUNT_AGREEMENTS).then(({ data }) => data);
}

updateConsents.invalidates = ['getConsents'];
export function updateConsents(params) {
  return http.put(URIS.GDPR_ACCOUNT_AGREEMENTS, params);
}
