import * as Sentry from '@sentry/browser';

import getEnvFromUrl from 'services/get-env-from-url';
import env from 'util/env';

export const init = () =>
  Sentry.init({
    dsn: env('SENTRY_DSN_URL'),
    environment: getEnvFromUrl(window.location.href),
  });

export const error = (err, message) => {
  Sentry.withScope((scope) => {
    if (message) {
      scope.setExtra('err_message', message);
    }

    Sentry.captureException(err);
  });
};

export const info = (message) => Sentry.captureMessage(message, 'info');
export const warning = (message) => Sentry.captureMessage(message, 'warning');
