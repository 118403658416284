import { get } from 'lodash';

import URIS from 'constants/uris';
import http from 'services/http';

// URIS.CHECKOUTS is a CORS_URIS constant, it doesn't /api prefixed to it
export function generateCheckoutUrl(checkoutHost, checkoutId) {
  return `${checkoutHost}${URIS.CHECKOUTS}/${checkoutId}`;
}

export function makePayment({ checkoutHost, checkoutId, paymentData }) {
  const paymentUrl = generateCheckoutUrl(checkoutHost, checkoutId);
  return http.put(paymentUrl, paymentData).then(({ data }) => {
    const paymentStatus = get(data, 'status');
    return paymentStatus === 'PAID' ? data : Promise.reject(data);
  });
}

export function createCheckout(params) {
  return http
    .post(URIS.TELEPHONE_PAYMENTS_CHECKOUTS, params)
    .then(({ data: checkout }) => checkout);
}

export function sendReceipt(id, params) {
  const receiptUrl = `${URIS.RECEIPTS}/${id}`;
  return http.post(receiptUrl, params);
}
