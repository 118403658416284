import URIS from 'constants/uris';
import http from 'services/http';

getMobileVerifications.operation = 'NO_OPERATION';
export function getMobileVerifications() {
  return http.get(URIS.ACCOUNT_MOBILE_VERIFICATIONS);
}

export function createMobileVerification(params) {
  return http.post(URIS.ACCOUNT_MOBILE_VERIFICATIONS, params);
}
