import Cookie from 'js-cookie';

import { isRunningInApp } from 'util/user-agent';
import {
  isPageVisibilitySupported,
  visibilityChangeEvent,
  isPageVisible,
} from 'util/page-visibility';
import { isProtectedRoute, redirectToLogin } from 'services/auth';

const SESSION_EXPIRATION_COOKIE = 'XSRF-TOKEN-EXPIRES';
const COOKIE_CHECK_TIMEOUT = 60000; // 1 min

let timeoutRef;

const checkSessionExpiration = () => {
  const sessionExpiration = Cookie.get(SESSION_EXPIRATION_COOKIE);
  const currentTime = new Date().getTime();
  const shouldRedirect = isProtectedRoute(window.location.pathname);

  clearTimeout(timeoutRef);

  if (!sessionExpiration || sessionExpiration <= currentTime) {
    if (shouldRedirect) {
      redirectToLogin();
    }

    if (isPageVisible()) {
      timeoutRef = setTimeout(checkSessionExpiration, COOKIE_CHECK_TIMEOUT);
    }
    return;
  }

  if (isPageVisible()) {
    timeoutRef = setTimeout(
      checkSessionExpiration,
      sessionExpiration - currentTime,
    );
  }
};

const handleVisibilityChange = () => {
  if (isPageVisible()) {
    checkSessionExpiration();
  } else {
    clearTimeout(timeoutRef);
  }
};

const initVisibilityChangeListener = () => {
  if (isPageVisibilitySupported()) {
    document.removeEventListener(
      visibilityChangeEvent,
      handleVisibilityChange,
      false,
    );
    document.addEventListener(
      visibilityChangeEvent,
      handleVisibilityChange,
      false,
    );
  }
};

export const init = () => {
  if (isRunningInApp()) {
    return;
  }

  initVisibilityChangeListener();
  checkSessionExpiration();
};
