import http from 'services/http';
import getEnvFromUrl from 'services/get-env-from-url';
import { DEBITOOR_URIS } from 'constants/uris';

const BFF_URL =
  getEnvFromUrl(window.location.href) === 'production'
    ? DEBITOOR_URIS.DEBITOOR_BFF_URL_PROD
    : DEBITOOR_URIS.DEBITOOR_BFF_URL_STAGE;

export function getInvoicesActivationStatus(
  solutionName,
  merchantId,
  sumupOTP,
) {
  return http.get(`${BFF_URL}/status/${solutionName}`, {
    headers: {
      merchantId,
      sumupOTP,
    },
  });
}

export function getInvoicesLink(solutionName, merchantId, sumupOTP) {
  return http.get(`${BFF_URL}/url/${solutionName}`, {
    headers: {
      merchantId,
      sumupOTP,
    },
  });
}
