import { get, noop } from 'lodash/fp';
import api from 'api';

// https://github.com/marcuswestin/WebViewJavascriptBridge

export const MESSAGES = {
  NO_DEVICE_FOUND: 'No device was found',
  NO_SESSION_INFO: 'No session info',
  DEFAULT_BRIDGE: 'Bridge Connected',
};

export const ACTIONS = {
  SHOW_PROGRESS: 'show_progress',
  DISMISS: 'dismiss',
  SEND_ACTION: 'send_action',
};

export const sendData = (data) =>
  new Promise((resolve, reject) => {
    const iosWebviewBridge = get(
      'webkit.messageHandlers.webviewBridge',
      window,
    );
    if (
      !iosWebviewBridge &&
      !window.WebViewJavascriptBridge &&
      !window.Android
    ) {
      reject(new Error(MESSAGES.NO_DEVICE_FOUND));
    }

    api.sessionInfo
      .getSessionInfo()
      .then((sessionInfo) => {
        const token = get('otp', sessionInfo);
        if (!token) {
          reject(new Error(MESSAGES.NO_SESSION_INFO));
        }
        const message = { ...data, token };

        if (iosWebviewBridge) {
          iosWebviewBridge.postMessage(message);
          resolve();
        } else if (
          window.WebViewJavascriptBridge &&
          window.WebViewJavascriptBridge.send
        ) {
          window.WebViewJavascriptBridge.send(message, (response) =>
            resolve(response),
          );
        }

        if (window.Android && window.Android.send) {
          window.Android.send(JSON.stringify(message));
          resolve();
        }
      })
      .catch(() => reject(new Error(MESSAGES.NO_SESSION_INFO)));
  });

export const sendAction = (data) =>
  sendData({
    directive: {
      action: ACTIONS.SEND_ACTION,
      params: {
        action: data,
      },
    },
  });

export const showProgress = (progress) =>
  sendData({
    directive: {
      action: ACTIONS.SHOW_PROGRESS,
      params: {
        progress,
      },
    },
  });

export const dismiss = () =>
  sendData({
    directive: {
      action: ACTIONS.DISMISS,
    },
  });

export const connectWebView = () =>
  new Promise((resolve) => {
    if (window.WebViewJavascriptBridge) {
      showProgress(100);
      resolve(window.WebViewJavascriptBridge);
    }

    if (get('webkit.messageHandlers.webviewBridge', window)) {
      showProgress(100);
      resolve();
    }

    if (window.Android) {
      document.addEventListener('AndroidSends', noop, false);
      showProgress(100);

      resolve();
    }

    document.addEventListener(
      'WebViewJavascriptBridgeReady',
      () => {
        resolve(window.WebViewJavascriptBridge);
        showProgress(100);
      },
      false,
    );
  });

export const defaultBridgeHandler = (bridge) =>
  bridge && bridge.init((message, cb) => cb && cb(MESSAGES.DEFAULT_BRIDGE));
