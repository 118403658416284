import URIS from 'constants/uris';
import http from 'services/http';

getMerchantProfile.operation = 'READ';
getMerchantProfile.idFrom = (response) => response.data.merchant_code;
export function getMerchantProfile() {
  return http.get(URIS.MERCHANT_PROFILE);
}

getMerchantProfileForChange.operation = 'READ';
getMerchantProfileForChange.idFrom = (response) => response.data.merchant_code;
export function getMerchantProfileForChange() {
  return http.get(toEditableProfile(URIS.MERCHANT_PROFILE));
}

updateMerchantProfile.invalidates = [
  'getMerchantProfile',
  'getMerchantProfileForChange',
];
export function updateMerchantProfile(params) {
  return http.put(URIS.MERCHANT_PROFILE, params);
}

createMerchantProfile.invalidates = [
  'getMerchantProfile',
  'getMerchantProfileForChange',
];
export function createMerchantProfile(params) {
  return http.put(toEditableProfile(URIS.MERCHANT_PROFILE), params);
}

updateMerchantProfileDraft.invalidates = [
  'getMerchantProfileForChange',
  'getMerchantProfile',
];
export function updateMerchantProfileDraft(params) {
  return http.post(URIS.MERCHANT_PROFILE_DRAFT, params);
}

completeMerchantProfile.operation = 'CREATE';
completeMerchantProfile.idFrom = 'ARGS';
export function completeMerchantProfile() {
  const editableProfile = toEditableProfile(URIS.ME);
  return http.post(`${editableProfile}/profile-submit`);
}

export function checkCompanyRegistrationNumber(params) {
  return http.post(URIS.CHECK_COMPANY_REGISTRATION_NUMBER, params);
}

export function toEditableProfile(url) {
  return url.replace('v0.1', 'v0.2');
}
