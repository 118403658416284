import URIS from 'constants/uris';
import http from 'services/http';

getCountry.operation = 'READ';
getCountry.idFrom = ({ data }) => data.id;
export function getCountry(country) {
  return http.get(`${URIS.COUNTRIES}/${country}`);
}

getCountries.operation = 'READ';
getCountries.idFrom = ({ data = [] } = {}) =>
  data.reduce((acc, { iso_code: isoCode }) => acc + isoCode);
export function getCountries() {
  return http.get(URIS.COUNTRIES);
}

getBrazilAddress.operation = 'READ';
getBrazilAddress.idFrom = 'ARGS';
export function getBrazilAddress(postCode) {
  return http
    .get(`${URIS.BRAZIL_ADDRESS_URI}/${postCode}`)
    .then(({ data }) => data);
}
