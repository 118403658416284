export default {
  OVERVIEW: 'overview',
  ACTIVATION: 'activate',
  TRANSACTIONS: 'transactions',
  CARD: 'card',
  CARD_OVERVIEW: 'card/overview',
  CARD_HISTORY: 'card/history',
  CARD_SIGNUP: 'card/signup',
  RESET_PASSWORD_LEGACY: 'reset_password',
  RESET_PASSWORD: 'reset-password',
  LOGIN: 'login',
  LOGOUT: 'logout',
  SETTINGS: 'settings',
  SUPPORT: 'support',
  READ: 'support/read',
  DEVELOPERS: 'developers',
  VIRTUAL_TERMINAL: 'virtual-terminal',
  EMPLOYEES: 'employees',
  DEVICES: 'devices',
  GDPR: 'gdpr',
  CATALOG: 'products',
  CATALOG_PRODUCT_IMPORT: 'products/import',
  REFERRALS: 'referrals',
  SIGNUP_CREATE: 'signup/create-account',
  SIGNUP_SHOP: 'signup/shop',
  SHOP: 'shop',
  SHOP_THANK_YOU: 'shop-thank-you',
  POST_PAYMENT: 'post-payment',
  CREATE_ACCOUNT_APPS: 'create-account-apps',
  START: 'start',
  ACCOUNT: 'account',
  SALES: 'sales',
  PAYOUTS: 'payouts',
  VERIFICATION: 'verification',
  VERIFICATION_DOCUMENTS: 'verification/documents',
  VERIFICATION_VIDEO: 'verification/video',
  ECOMFULL: 'online-store',
  ACCOUNTING: 'accounting',
  INVOICING: 'invoices',
  INVOICING_ACTIVATION: 'invoices-activation',
  INVOICING_ITALY: 'invoices-italy',
  ECOMLITE: 'online-selling',
  ECOMLITE_UPGRADE: 'online-selling/upgrade',
  ECOMLITE_ORDERS: 'online-selling/orders',
  RECEIPTS: 'receipts',
  VERIFICATION_PHOTO_ID: 'verification/photo-id',
  LENDING: 'lending',
  LENDING_OFFER: 'lending/offer',
  FISCALIZATION: 'fiscalization',
  REPORTS: 'reports',
  SUBSCRIPTIONS: 'subscriptions',
};
