import URIS from 'constants/uris';
import http from 'services/http';

export function createNotification({ nid }) {
  return http.put(`${URIS.NOTIFICATIONS}/${nid}`);
}

export function getNotifications() {
  return http.get(`${URIS.NOTIFICATIONS}`);
}
