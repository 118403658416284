import { mapValues, replace } from 'lodash/fp';

const API_PREFIX = '/api';
const prefixUris = mapValues((uri) => `${API_PREFIX}${uri}`);
const stripPrefix = replace(/\/api(?!$)/, '');

const URIS = {
  ME: '/v0.1/me',
  AUTH: '/oauth',
  LOGOUT: '/logout',
  RESET_PASSWORD: '/v0.1/password-reset',
  CREDENTIALS: '/v0.1/me/credentials',
  SESSION_INFO: '/session-info',
  ONE_TIME_TOKENS: '/one-time-tokens',
  USER_EVENTS: '/v0.1/me/user-events',
  FEATURE_TOGGLES: '/v0.1/feature-toggles',
  GLOBAL_FEATURE_TOGGLES: '/v0.1/me/feature-toggles',
  NOTIFICATIONS: '/v0.1/me/notifications',
  APP_SETTINGS: '/v0.1/me/app-settings',

  PAYOUT: '/v0.1/me/balance/payout',
  BALANCE: '/v0.1/me/balance',
  SETTLEMENT_REPORTS: '/v0.1/me/settlement-reports',
  BANK_ACCOUNTS: '/v0.1/me/merchant-profile/bank-accounts',
  BANKS_FULL: '/v0.1/me/merchant-profile/bank-accounts/full',
  BANKS_META: '/v0.1/users/0/merchant-profile/bank-accounts/meta/',
  BANKS_VERIFICATION: '/v0.1/me/merchant-profile/bank-accounts/pending/verify',
  BANKS_REMOVE_PENDING: '/v0.1/me/merchant-profile/bank-accounts/pending',
  BANKS_SWIFT_SUGGESTION:
    '/v0.1/me/merchant-profile/bank-accounts/swift-suggestion',
  SUPPORTED_BANKS: '/v0.1/supported-banks',
  PREPAID_CARD: '/v0.1/me/prepaid-card',
  ACTIVATE_PREPAID_CARD: '/v0.1/me/prepaid-card/activate',
  MIGRATION_PREPAID_CARD: '/v0.1/me/migration/prepaid-card',
  ACTIVATE_MIGRATION_PREPAID_CARD: '/v0.1/me/migration/prepaid-card/activate',

  ACCOUNT_MOBILE_VERIFICATIONS: '/v0.1/me/mobile-verifications',
  ACCOUNT_ACTIVATE: '/v0.1/me/activate',
  ACCOUNT_DOCUMENTS: '/v0.1/me/documents',
  ACCOUNT_DOCUMENTS_SETTINGS: '/v0.1/me/documents_settings',

  SHOP_ORDERS: '/v0.1/me/shipping-orders',
  SHOP_GUEST_ORDERS: '/v0.1/shipping-orders',
  SHOP_ORDERS_WIRECARD: '/v0.1/me/shipping-orders/wirecard',
  SHOP_PAYMENT_TYPES: '/v0.1/shop/payment-types',
  SHOP_PRODUCTS: '/v0.1/shop/products',
  SHOP_PROCESS_PAYMENTS: '/v0.1/shop/payments',
  SHOP_CARRIERS: '/v0.1/shop/carriers',
  SHOP_PAYMENT_CREATE_CHECKOUT: '/v0.1/me/checkouts/shop',
  VOUCHERS: '/v0.1/promotions/vouchers',
  RISK_PROFILE: '/v0.1/me/risk-profile',
  ID_VERIFICATIONS: '/v0.1/me/id-verifications',
  REGISTRATIONS: '/v0.1/me/registration',
  CREATE_USER: '/v0.1/users',
  ACCOUNT: '/v0.1/me/users/account',
  SUBACCOUNTS: '/v0.1/me/accounts',
  PERMISSIONS: '/v0.1/me/permissions',
  PERSONAL_PROFILE: '/v0.1/me/personal-profile',
  PERSONAL_PROFILE_DRAFT: '/v0.2/me/personal-profile/changes',
  LEGAL_TYPES: '/v0.1/legal-types',
  MERCHANT_PROFILE: '/v0.1/me/merchant-profile',
  MERCHANT_PROFILE_DRAFT: '/v0.2/me/merchant-profile/changes',
  MERCHANT_PROFILE_SETTINGS: '/v0.1/me/merchant-profile/settings',
  MERCHANT_CATEGORIES: '/v0.1/merchant-categories',
  CHECK_COMPANY_REGISTRATION_NUMBER:
    '/v0.1/me/check-company-registration-number',

  TRANSACTION_SEARCH: '/v0.1/me/transactions/search',
  TRANSACTION_HISTORY: '/v0.1/me/transactions/history',
  TRANSACTION_ACCOUNTS: '/v0.1/me/transactions/accounts',
  TRANSACTION_DETAILS: '/v0.1/me/transactions',
  TRANSACTION_REFUND: '/v0.1/me/refund',
  TRANSACTIONS_EXPORT_OLD: '/v0.1/me/transaction-history-export',
  TRANSACTIONS_EXPORT: '/v1.0/me/transaction-history-export',
  TRANSACTIONS_MAP: '/maps',

  TELEPHONE_PAYMENTS_CHECKOUTS: '/v0.1/me/moto-payments',

  REFERRALS: '/v0.1/referrals/campaigns',
  REFERRAL_CODES: '/v0.1/referrals/referral-codes',
  REFERRAL_EMAIL_SHARES: '/v0.1/referrals/referrer/MERCHANT_CODE/email-shares',
  REFERRAL_REWARD_HISTORY: '/v0.1/referrals/rewards',
  REFERRAL_SHARE_INVITATION_EVENTS: '/v0.1/referrals/share-invitation-events',
  RECEIPTS: '/v1.0/me/receipts',

  CLIENT_PROJECTS: '/v0.1/me/client-projects',
  PARTNERSHIP: '/v0.1/me/partner-profile',

  OVERVIEW: '/v0.1/me/analytics',

  COUNTRIES: '/v0.1/countries',
  BRAZIL_ADDRESS_URI: '/correios',

  DEVICES: '/v0.1/me/card-terminals',
  DEVICES_ACTIVATION_CODE: '/v0.1/me/device-activation-code',

  VALIDATIONS_USERS: '/v0.1/users/meta',
  VALIDATIONS_MERCHANT_PROFILE: '/v0.1/users/0/merchant-profile/meta',
  VALIDATIONS_PERSONAL_PROFILE: '/v0.1/users/0/personal-profile/meta',
  VALIDATIONS_SHIPPING_ORDERS: '/v0.1/users/0/shipping-orders/meta',
  VALIDATIONS_BANK_ACCOUNT: '/v0.1/users/0/merchant-profile/bank-accounts/meta',
  VALIDATIONS_DYNAMIC_DESCRIPTOR:
    '/v0.1/users/0/merchant-profile/dynamic-descriptor/meta',
  VALIDATIONS_DOING_BUSINESS_AS:
    '/v0.1/users/0/merchant-profile/doing-business-as/meta',

  GDPR_RULES: '/v0.1/gdpr-agreements',
  GDPR_ACCOUNT_AGREEMENTS: '/v0.1/me/gdpr-agreements',

  CATALOG: '/me/catalog',
  TAXES: '/me/taxes',
  CATALOG_IMAGES_TOKEN: '/me/catalog/upload_policy',

  CARD_LIST: '/v0.1/me/card',
  CARD_SET_STATUS: '/v0.1/me/cards/:id/status',
  CARD_SET_PIN: '/v0.1/me/cards/:id/pin',
  CARD_TRANSACTIONS: '/v0.1/me/card/transactions',
  CARD_SIGNUP: '/v0.1/me/card/onboard',
  CARD_TRANSFER: '/v0.1/me/card/transferout',
  IBAN_TRANSFER: '/v0.1/me/card/transfer',
  GET_TRANSFERS: '/v0.1/me/card/transfer/:reference',
  CANCEL_TRANSFER: '/v0.1/me/card/transfer/:reference/cancel',

  PAYOUT_HISTORY: '/v0.1/me/payout-history',
  PAYOUT_DETAILS: '/v0.1/me/payout-history/:id/transactions',

  DYNAMIC_DESCRIPTOR: '/v0.1/me/merchant-profile/dynamic-descriptor',

  CUSTOMISABLE_RECEIPTS: '/v0.1/me/merchant-profile/doing-business-as',
  LENDING_GET_LOANS: '/me/lending/loans',
  LENDING_GET_FILES: '/me/lending/files',

  POS_API: '/pos/v1beta/me',

  REPORTS_AVAILABLE: '/me/reports/available',
  EXPORT_REVENUE_REPORT: '/me/revenue-report',
  EXPORT_SALES_REPORT: '/me/sales-journal',
  EXPORT_GOBD_REPORT: '/me/reports/gobd',
  TAX_REPORT: '/v0.1/me/la-tassa/reports',

  SUPPORT_READ: '/v0.1/me/read/articles',

  SUBSCRIPTIONS: '/subscriptions',
};

/**
 * CORS_URIS are used by external components,
 * such as https://api.sumup.com/${external_uri}
 */
const CORS_URIS = {
  SHOP_PAYMENTS: '/v0.1/me/shipping-orders',
  CHECKOUTS: '/v0.1/checkouts',
  CATALOG_IMAGES: '/images',
};

const SHOPLO_URIS = {
  SUMUP_LINK_PAYMENTS: '/payments',
  SUMUP_LINK_SHIPPINGS: '/shippings',
  SUMUP_LINK_TERMS_AND_CONDITIONS: '/terms-and-conditions',
  SUMUP_LINK_LOGO: '/logo',
  SUMUP_LINK_GENERAL: '/general',
  SUMUP_LINK_NOTIFICATION: '/notification',
  SUMUP_LINK_SOCIAL_MEDIA: '/social-media',
  SUMUP_LINK_MERCHANT_STORE: '/check-merchant-store',
  SHOP_DETAILS: '/shop/details',
  SUMUP_LINK_UPGRADE_INFO: '/upgrade-instructions',
  ORDERS_LIST: '/ecomlite/orders',
  ORDER_DETAILS: '/ecomlite/orders',
};

const DEBITOOR_BFF_PROD = 'https://sumup-web-bff.debitoor.com';
const DEBITOOR_BFF_STAGE = 'https://ds-debitoor-sumup-web-bff.herokuapp.com';
const DEBITOOR_URIS = {
  DEBITOOR_BFF_URL_TEXTS_PROD: `${DEBITOOR_BFF_PROD}/api/texts`,
  DEBITOOR_BFF_URL_TEXTS_STAGE: `${DEBITOOR_BFF_STAGE}/api/texts`,
  DEBITOOR_BFF_URL_PROD: `${DEBITOOR_BFF_PROD}/api/solution`,
  DEBITOOR_BFF_URL_STAGE: `${DEBITOOR_BFF_STAGE}/api/solution`,
};

export { stripPrefix };
export { DEBITOOR_URIS, SHOPLO_URIS };
export default { ...prefixUris(URIS), ...CORS_URIS };
