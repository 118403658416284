import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';
import 'core-js/es6/promise';
import 'core-js/es6/symbol';
import 'core-js/es6/string';
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/from';
import 'core-js/fn/object/assign';
import 'core-js/fn/object/entries';
import 'core-js/fn/object/values';
import 'core-js/fn/string/pad-start';
import 'svgxuse'; // External SVG references
import 'element-remove'; // support for ChildNode.remove() in IE
