import http from 'services/http';
import URIS from 'constants/uris';

getCode.operation = 'READ';
getCode.idFrom = (boleto) => boleto.barcode;
export function getCode(orderId) {
  return http
    .get(`${URIS.SHOP_PROCESS_PAYMENTS}/boleto?order_id=${orderId}`, {
      headers: {
        Accept: 'barcode',
      },
    })
    .then(({ data }) => data);
}
