import { compose, identity, isFunction, omit, pickBy } from 'lodash/fp';
import queryString from 'query-string';

import { getGlobalMerchantCode } from 'components/UserProvider';

/**
 * This is only a thin layer allowing us to push events
 * and pageviews to Google Analytics via Google Tag Manager.
 *
 * If you want to track specific data, for example extra meta data
 * for the shop, please add that data to the events. If you are
 * unsure what that data might be, please refer to the legacy
 * analytics service and have a look at the specialized tracking
 * methods there.
 */

export function sendEvent({ category, label, ...customFields } = {}) {
  send({
    'event': 'interaction',
    'target': category,
    'target-properties': label,
    ...customFields,
  });
}

export function sendPageview(overrides = {}) {
  send({
    'event': 'content-view',
    'content-name': getCleanLocation(),
    ...overrides,
  });
}

function send(data) {
  // TODO: add logging when dataLayer is missing on a relevant
  //       environment.
  if (window.dataLayer && isFunction(window.dataLayer.push)) {
    window.dataLayer.push(
      pickBy(identity, {
        ...data,
        Merchant_Code: getGlobalMerchantCode(),
      }),
    );
  }
}

function getCleanLocation() {
  const QUERY_PARAM_BLACKLIST = ['email'];
  const { pathname, search } = window.location;
  const safeQueryString = compose(
    queryString.stringify,
    omit(QUERY_PARAM_BLACKLIST),
    queryString.parse,
  )(search);
  return safeQueryString.length ? `${pathname}?${safeQueryString}` : pathname;
}
