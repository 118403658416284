import URIS from 'constants/uris';
import http from 'services/http';

getCategories.operation = 'READ';
getCategories.idFrom = 'ARGS';
export function getCategories() {
  return http.get(`${URIS.CATALOG}/categories`).then(({ data }) => data);
}

createCategory.operation = 'CREATE';
createCategory.invalidates = ['getCategories'];
export function createCategory(name) {
  return http
    .post(`${URIS.CATALOG}/categories`, { name })
    .then(({ data }) => data);
}
deleteCategory.operation = 'DELETE';
deleteCategory.invalidates = ['getCategories'];
export function deleteCategory(categoryId) {
  return http.delete(`${URIS.CATALOG}/categories/${categoryId}`);
}

updateCategory.operation = 'UPDATE';
updateCategory.invalidates = ['getCategories'];
export function updateCategory({ id, name }) {
  return http
    .put(`${URIS.CATALOG}/categories/${id}`, { name })
    .then(({ data }) => data);
}

getCategoryProducts.operation = 'READ';
getCategoryProducts.idFrom = 'ARGS';
export function getCategoryProducts(categoryId, page = 1, size = 9999) {
  return http
    .get(
      `${URIS.CATALOG}/categories/${categoryId}/products?page=${page}&size=${size}`,
    )
    .then(({ data }) => data);
}
