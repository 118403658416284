import URIS from 'constants/uris';
import http from 'services/http';

/**
 * Fetches app settings from the SumUp users service
 */
getAppSettings.operation = 'READ';
getAppSettings.idFrom = () => 'ARGS';
export function getAppSettings() {
  return http.get(URIS.APP_SETTINGS).then(({ data }) => data);
}
