import React, { Fragment, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTransition, animated } from 'react-spring';
import { uniqueId } from 'lodash/fp';
import keyMirror from 'key-mirror-nested';

import NotificationContext from './NotificationContext';
import NotificationController from './NotificationController';

const NOTIFICATION_PREFIX = 'notification-';
const BASE_SELECTOR = {
  NOTIFICATIONS_PROVIDER: {
    LIST_WRAPPER: null,
  },
};
export const SELECTOR = keyMirror(BASE_SELECTOR);

const ListWrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    right: ${theme.spacings.peta};
    bottom: ${theme.spacings.peta};
    z-index: ${theme.zIndex.modal};

    ${theme.mq.untilMega} {
      bottom: ${theme.spacings.byte};
      right: ${theme.spacings.byte};
      width: calc(100% - ${theme.spacings.mega});
    }
  `};
`;

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [refMap] = useState(new Map());

  const transition = useTransition(notifications, {
    key: (notification) => notification.id,
    from: {
      transform: 'translateX(100%)',
      height: 0,
    },
    enter: (notification) => (next) =>
      next({
        config: { duration: 0 },
        height: refMap.get(notification.id).offsetHeight,
      }).then(() =>
        next({
          transform: 'translateX(0%)',
        }),
      ),
    leave: () => (next) =>
      next({
        config: { duration: 200 },
        transform: 'translateX(120%)',
      }).then(() =>
        next({
          config: { duration: 200 },
          height: 0,
        }),
      ),
  });

  const addNotification = (title, options = {}) => {
    const id = uniqueId(NOTIFICATION_PREFIX);

    setNotifications((n) => [{ id, title, ...options }, ...n]);

    return id;
  };

  const removeNotification = useCallback((notificationId) => {
    setNotifications((n) => n.filter(({ id }) => id !== notificationId));
  }, []);

  const [contextValue] = useState({ addNotification, removeNotification });

  return (
    <Fragment>
      <NotificationContext.Provider value={contextValue}>
        {children}
      </NotificationContext.Provider>

      <ListWrapper
        data-selector={SELECTOR.NOTIFICATIONS_PROVIDER.LIST_WRAPPER}
        role="status"
        aria-live="polite"
      >
        {transition((values, notification) => (
          <animated.div style={values}>
            <NotificationController
              ref={(ref) => ref && refMap.set(notification.id, ref)}
              onRemove={removeNotification}
              {...notification}
            />
          </animated.div>
        ))}
      </ListWrapper>
    </Fragment>
  );
};

export default NotificationProvider;
