import { get } from 'lodash';

import URIS from 'constants/uris';
import http from 'services/http';

export function getCheckoutByOrder(orderId) {
  return http
    .get(`${URIS.SHOP_PAYMENT_CREATE_CHECKOUT}/${orderId}`)
    .then(({ data: checkout }) => checkout);
}

export function createCheckout(orderId, params) {
  return http
    .post(`${URIS.SHOP_PAYMENT_CREATE_CHECKOUT}`, {
      ...params,
      order_id: orderId,
    })
    .then(({ data: checkout }) => checkout);
}

export function processCheckout({ checkoutHost, checkoutId, paymentData }) {
  return http
    .put(`${checkoutHost}${URIS.CHECKOUTS}/${checkoutId}`, paymentData)
    .then(({ data }) =>
      get(data, 'status') === 'PAID' ? data : Promise.reject(data),
    );
}

export function payByBoleto({
  order,
  context,
  fbuyRefCode,
  referrer,
  partner,
  tags,
  shareId,
}) {
  const request = {
    order_id: order.id,
    payment_type: 'boleto',
    context,
    fbuy_ref_code: fbuyRefCode,
    referrer,
    partner,
    tags,
    share_id: shareId,
  };
  return http
    .post(URIS.SHOP_PROCESS_PAYMENTS, request)
    .then(({ data }) => data);
}
