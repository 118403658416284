import { removeLocale } from 'components/Link';
import { getGlobalMerchantCode } from 'components/UserProvider';

export const handleDispatch = (event) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    ...event,
    userId: getGlobalMerchantCode(),
  });
};

/**
 * Removing any locale from the url
 * Remove the initial / from the url
 */
export const normalizeTrackingView = (pathname = '') =>
  removeLocale(pathname).substring(1);
