import UAParser from 'ua-parser-js';
import URIS from 'constants/uris';
import http from 'services/http';

getReferralData.operation = 'READ';
export function getReferralData(params) {
  return http
    .get(`${URIS.REFERRALS}?locale=${params.locale}&country=${params.country}`)
    .then(({ data }) => data);
}

export function getReferralCodeData(params) {
  return http.post(URIS.REFERRAL_CODES, params).then(({ data }) => data);
}

export function getRewardsHistory(merchantCode) {
  return http
    .get(`${URIS.REFERRAL_REWARD_HISTORY}?id=${merchantCode}&per=50`)
    .then(({ data }) => data);
}

export function createShareInvitationEvent(shareOption, additionalData = null) {
  const uaResult = new UAParser().getResult();
  return http
    .post(URIS.REFERRAL_SHARE_INVITATION_EVENTS, {
      device_os: uaResult.browser.name,
      app_version: uaResult.browser.version,
      share_option: shareOption,
      additional_data: additionalData,
    })
    .then(({ data }) => data);
}
