import React, { Component } from 'react';
import { hoistStatics, wrapDisplayName } from 'recompose';
import { UserConsumer } from 'components/UserProvider';

const withUser = (WrappedComponent) => {
  class WithUser extends Component {
    WrappedComponent = WrappedComponent;

    render() {
      return (
        <UserConsumer>
          {(user) =>
            user ? <WrappedComponent {...this.props} user={user} /> : null
          }
        </UserConsumer>
      );
    }
  }

  WithUser.displayName = wrapDisplayName(WrappedComponent, 'withUser');

  return WithUser;
};

export default hoistStatics(withUser);
