import React, { Component } from 'react';
import { hoistStatics, wrapDisplayName } from 'recompose';

import api from 'api';

const withOneTimeToken = (WrappedComponent) =>
  class extends Component {
    WrappedComponent = WrappedComponent;

    static propTypes = WrappedComponent.propTypes;

    displayName = wrapDisplayName(WrappedComponent, 'withOneTimeToken');

    state = {
      otp: null,
    };

    componentDidMount() {
      api.oneTimeTokens.getOneTimeToken().then((otp) => {
        this.setState({ otp });
      });
    }

    render() {
      const { otp } = this.state;

      if (!otp) {
        return null;
      }

      return <WrappedComponent {...this.props} otp={otp} />;
    }
  };

export default hoistStatics(withOneTimeToken);
