import URIS from 'constants/uris';
import http from 'services/http';

export function getReceipt() {
  return http
    .get(URIS.CUSTOMISABLE_RECEIPTS)
    .then(({ data: receipt }) => receipt);
}

export function updateReceipt(params) {
  return http
    .put(URIS.CUSTOMISABLE_RECEIPTS, params)
    .then(({ data: receipt }) => receipt);
}
