import URIS from 'constants/uris';
import http from 'services/http';

getDevices.operation = 'READ';
getDevices.idFrom = () => 'ALL';
export function getDevices() {
  return http.get(URIS.DEVICES).then(({ data }) => data);
}

getActivationCode.operation = 'READ';
getActivationCode.idFrom = 'ARGS';
export function getActivationCode() {
  return http.get(URIS.DEVICES_ACTIVATION_CODE).then(({ data }) => data);
}
