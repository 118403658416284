import { css } from '@emotion/core';
import { light as theme } from '@sumup/design-tokens';

const styles = css`
  .sidebarMinimized button {
    background-color: ${theme.colors.b500} !important;
    border-radius: ${theme.spacings.giga} !important;
    margin-bottom: ${theme.spacings.kilo};
  }

  .helpButtonDisabled {
    display: none !important;
  }

  .helpButton {
    bottom: ${theme.spacings.kilo} !important;

    :focus {
      &::before {
        border-radius: ${theme.spacings.giga} !important;
      }
    }

    .uiButton {
      background-color: ${theme.colors.b500} !important;
      border-radius: ${theme.spacings.giga} !important;

      &:focus {
        outline: 1px solid ${theme.colors.b500};
      }

      .helpButtonLabel {
        justify-content: left !important;
      }

      .embeddedServiceIcon {
        margin: 0 10px;
      }
    }
  }

  @media screen and (max-width: ${theme.breakpoints.tera}px) {
    .sidebarMinimized {
      right: 0;
      left: inherit !important;
      transform: rotate(90deg) !important;
      display: block !important;
      top: 45px !important;
      position: fixed !important;
      width: 20px !important;
    }

    .sidebarMinimized button {
      background-color: ${theme.colors.b500} !important;
      height: 20px !important;
      min-width: 8em !important;
      border-radius: ${theme.spacings.giga} !important;
      transform: rotate(180deg);
      position: relative !important;
      width: 0px !important;
      right: 0 !important;
      margin-bottom: 0;
    }

    .embeddedServiceHelpButton {
      right: 0;
      left: inherit !important;
      transform: rotate(90deg);
      display: block;
      top: 45px !important;
      position: fixed;
    }

    .helpButton {
      position: fixed;
      top: 0;
      left: 40px; // move up and down

      :focus {
        &::before {
          border-radius: ${theme.spacings.giga} !important;
        }
      }

      .uiButton {
        background-color: ${theme.colors.b500} !important;
        height: 20px !important;
        min-width: 8em !important;
        border-radius: ${theme.spacings.giga} !important;
        transform: rotate(180deg);

        &:focus {
          outline: 1px solid ${theme.colors.b500};
        }
        .embeddedServiceIcon {
          display: none !important;
        }
      }
    }
  }

  .embeddedServiceLiveAgentStateChatInputFooter
    .embeddedServiceLiveAgentStateChatUnreadMessageBanner,
  .chasitorInputWrapper .footerMenuWrapper {
    display: none;
  }
`;

export default styles;
