import URIS from 'constants/uris';
import http from 'services/http';

/*
 * Since the session info is not special object
 * we have to use a fake id to store it in the cache
 */

getSessionInfo.operation = 'READ';
getSessionInfo.idFrom = () => 'getSessionInfoId';
export function getSessionInfo({ token } = {}) {
  return http
    .get(`${URIS.SESSION_INFO}${token ? `?c=${token}` : ''}`)
    .then(({ data }) => data);
}

updateSessionInfo.operation = 'NO_OPERATION';
updateSessionInfo.invalidates = ['getSessionInfo'];
export function updateSessionInfo() {
  return Promise.resolve(true);
}
