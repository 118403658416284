import URIS from 'constants/uris';
import http from 'services/http';
import logger from 'services/logger';

const errorHandler = (err) => {
  logger.error(err, 'Subscriptions Backend Error');
  return Promise.reject(err);
};

/**
 * Fetch plans from the SumUp Subscriptions API via a proxy in the dashboard-backend
 */
getPlans.operation = 'READ';
export function getPlans() {
  return http
    .get(`${URIS.SUBSCRIPTIONS}/plans`)
    .then(({ data }) => data)
    .catch(errorHandler);
}

/**
 * Fetch products from the SumUp Subscriptions API via a proxy in the dashboard-backend
 */
getProducts.operation = 'READ';
export function getProducts() {
  return http
    .get(`${URIS.SUBSCRIPTIONS}/products`)
    .then(({ data }) => data)
    .catch(errorHandler);
}

/**
 * Fetch current user subscriptions from the SumUp Subscriptions API via a proxy in the dashboard-backend
 */
getSubscriptions.operation = 'READ';
export function getSubscriptions() {
  return http
    .get(`${URIS.SUBSCRIPTIONS}/subscriptions`)
    .then(({ data }) => data)
    .catch(errorHandler);
}

/**
 * Pause user subscription from the SumUp Subscriptions API via a proxy in the dashboard-backend
 */
cancelSubscription.operation = 'READ';
cancelSubscription.idFrom = 'ARGS';
export function cancelSubscription(subscriptionId) {
  return http
    .delete(`${URIS.SUBSCRIPTIONS}/subscriptions/${subscriptionId}`)
    .then(({ data }) => data)
    .catch(errorHandler);
}

/**
 * Resume user subscription from the SumUp Subscriptions API via a proxy in the dashboard-backend
 */
resumeSubscription.operation = 'READ';
resumeSubscription.idFrom = 'ARGS';
export function resumeSubscription(subscriptionId) {
  return http
    .post(`${URIS.SUBSCRIPTIONS}/subscriptions/${subscriptionId}`)
    .then(({ data }) => data)
    .catch(errorHandler);
}

/**
 * Get terms and conditions for given product from the SumUp Subscriptions API
 */
resumeSubscription.operation = 'READ';
resumeSubscription.idFrom = () => 'ARGS';
export function getProductLegalDocuments(productId, locale) {
  return http
    .get(`${URIS.SUBSCRIPTIONS}/products/${productId}/legal/${locale}`)
    .then(({ data }) => data);
}
