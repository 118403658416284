import deviceFingerPrint from '@sumup/astraea-js';

let deviceId = null;

const generateDeviceFingerPrint = () =>
  // eslint-disable-next-line consistent-return
  new Promise((resolve) => {
    if (deviceId) {
      return resolve(deviceId);
    }

    try {
      deviceFingerPrint(
        {
          regular_plugins: { include: false },
          canvas: { include: false },
          webgl: { include: false },
          js_fonts: { include: false },
        },
        (fingerprint) => {
          deviceId = fingerprint;
          resolve(fingerprint);
        },
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}
  });

export const getDeviceFingerPrint = () => deviceId;
export default generateDeviceFingerPrint;
