import URIS from 'constants/uris';
import http from 'services/http';

getBankAccounts.operation = 'READ';
getBankAccounts.idFrom = () => 'getBankAccountsId';
export function getBankAccounts() {
  return http.get(URIS.BANK_ACCOUNTS);
}

includeDisabledBankAccounts.operation = 'READ';
includeDisabledBankAccounts.idFrom = () => 'includeDisabledBankAccountsId';
export function includeDisabledBankAccounts() {
  return http.get(`${URIS.BANK_ACCOUNTS}?include_disabled=true`);
}

getSupportedBanks.operation = 'READ';
getSupportedBanks.idFrom = 'ARGS';
export function getSupportedBanks(country) {
  return http.get(`${URIS.SUPPORTED_BANKS}/${country}`);
}

/**
 * Add a new bank account and return the deferred promise
 */
createBankAccount.invalidates = ['getBankAccounts'];
export function createBankAccount(params) {
  return http.post(URIS.BANK_ACCOUNTS, params);
}

removePendingAccount.operation = 'DELETE';
removePendingAccount.invalidates = ['getBankAccounts'];
removePendingAccount.idFrom = () => 'removePendingAccountId';
export function removePendingAccount() {
  return http.delete(URIS.BANKS_REMOVE_PENDING);
}

sendVerificationCode.invalidates = [
  'getBankAccounts',
  'includeDisabledBankAccounts',
];
export function sendVerificationCode(params) {
  return http.post(URIS.BANKS_VERIFICATION, params);
}

export function getSwiftSuggestion(params) {
  return http.post(URIS.BANKS_SWIFT_SUGGESTION, params);
}
