import URIS from 'constants/uris';
import http from 'services/http';

requestCard.operation = 'CREATE';
export function requestCard(params) {
  return http.post(URIS.PREPAID_CARD, params).then(({ data }) => data);
}

activateCard.operation = 'NO_OPERATION';
export function activateCard(params) {
  return http.post(URIS.ACTIVATE_PREPAID_CARD, params).then(({ data }) => data);
}

getCardStatus.operation = 'READ';
export function getCardStatus() {
  return http.get(URIS.PREPAID_CARD).then(({ data }) => data);
}
