import { uniqueId } from 'lodash/fp';

import URIS from 'constants/uris';
import http from 'services/http';

getCardTransactions.operation = 'READ';
getCardTransactions.idFrom = uniqueId;
export function getCardTransactions(params) {
  return http.get(URIS.CARD_TRANSACTIONS, params).then(({ data }) => data);
}

getCards.operation = 'READ';
getCards.idFrom = () => 'ALL';
export function getCards() {
  return http.get(URIS.CARD_LIST);
}

getUpdatedCards.operation = 'READ';
getUpdatedCards.idFrom = () => 'ALL';
getUpdatedCards.invalidates = ['getCards', 'getUpdatedCards'];
export function getUpdatedCards() {
  return http.get(URIS.CARD_LIST);
}

setCardStatus.operation = 'NO_OPERATION';
setCardStatus.invalidates = ['getCards'];
export function setCardStatus(cardId, params) {
  return http.put(URIS.CARD_SET_STATUS.replace(':id', cardId), params);
}

setCardPin.operation = 'NO_OPERATION';
setCardPin.invalidates = ['getCards'];
export function setCardPin(cardId, pin) {
  return http.put(URIS.CARD_SET_PIN.replace(':id', cardId), { pin });
}

issueCard.operation = 'NO_OPERATION';
issueCard.invalidates = ['getCards'];
export function issueCard(params) {
  return http.post(URIS.CARD_SIGNUP, params);
}

transferFunds.invalidates = ['getCardTransactions'];
export function transferFunds(params) {
  return http.post(URIS.CARD_TRANSFER, params);
}

transferFundsToIban.invalidates = ['getCardTransactions'];
export function transferFundsToIban(params) {
  return http.post(URIS.IBAN_TRANSFER, params);
}

getTransfers.invalidates = ['getCardTransactions'];
export function getTransfers(reference) {
  return http.get(URIS.GET_TRANSFERS.replace(':reference', reference));
}

cancelTransfer.invalidates = ['getCardTransactions'];
export function cancelTransfer(reference) {
  return http.put(URIS.CANCEL_TRANSFER.replace(':reference', reference));
}
