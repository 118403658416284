import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';

import { createGetLocale, addLocale } from 'components/Link';
import { getValidLocale } from 'util/localization';

const getLocale = createGetLocale({ validateLocale: false });

const LocaleRedirect = ({ pathname, children }) => {
  const locale = getLocale(pathname);
  const validLocale = getValidLocale({}, locale).toLowerCase();

  if (locale && validLocale !== locale) {
    return (
      <Redirect
        noThrow
        to={`${addLocale(validLocale, pathname)}${window.location.search}`}
      />
    );
  }

  return children;
};

LocaleRedirect.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default LocaleRedirect;
