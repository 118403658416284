import URIS from 'constants/uris';
import http from 'services/http';

export function resetPassword(params) {
  return http.post(URIS.RESET_PASSWORD, params);
}

export function setNewPassword(params) {
  return http.put(URIS.RESET_PASSWORD, params);
}

export function changePassword(params) {
  return http.put(URIS.CREDENTIALS, params);
}
