import http from 'services/http';
import { SHOPLO_URIS } from 'constants/uris';

let httpInstance = null;

export function getBaseUrl(API_URL, EcomLite) {
  return `https://${EcomLite.storeName}.${API_URL}/api/v3/admin/ecomlite/settings`;
}

export function getStarterUrl(API_URL, EcomLite) {
  return `https://${EcomLite.storeName}.${API_URL}/api/starter/admin/settings`;
}

export function getShopUrl(API_URL, EcomLite) {
  return `https://${EcomLite.storeName}.${API_URL}/api/v3/admin/shop`;
}

export function getUpgradeUrl(API_URL, EcomLite) {
  return `https://${EcomLite.storeName}.${API_URL}/ajax?method=admin.account.upgradeStarter&plan=${EcomLite.plan}`;
}

const createInstance = (API_URL, EcomLite) => {
  httpInstance = http.create({
    baseURL: getBaseUrl(API_URL, EcomLite),
    headers: { common: { Authorization: `Bearer ${EcomLite.jwt}` } },
  });

  return httpInstance;
};

const getHttpInstance = (API_URL, EcomLite) =>
  httpInstance || createInstance(API_URL, EcomLite);

export function getOnlineStoreCredentials(otp, url) {
  return http.post(`${url}/api/sumup/ecomlite/login`, { otp });
}

export function registerStore(otp, url) {
  return http.post(`${url}/api/sumup/ecomlite/register`, { otp });
}

export function getSettings(API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL, EcomLite);
  instance.defaults.baseURL = getBaseUrl(API_URL, EcomLite);
  return instance.get();
}

export function sendUpgradeInfo(API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL, EcomLite);
  instance.defaults.baseURL = getShopUrl(API_URL, EcomLite);
  return instance.post(SHOPLO_URIS.SUMUP_LINK_UPGRADE_INFO);
}

export function upgradeOnlineStore(API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL, EcomLite);
  instance.defaults.baseURL = getUpgradeUrl(API_URL, EcomLite);
  return instance.get();
}

export function getPayments(API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL, EcomLite);
  instance.defaults.baseURL = getBaseUrl(API_URL, EcomLite);
  return instance.get(SHOPLO_URIS.SUMUP_LINK_PAYMENTS);
}

export function getShippings(API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL, EcomLite);
  instance.defaults.baseURL = getStarterUrl(API_URL, EcomLite);
  return instance.get(SHOPLO_URIS.SUMUP_LINK_SHIPPINGS);
}

export function getTermsAndConditions(API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL, EcomLite);
  instance.defaults.baseURL = getBaseUrl(API_URL, EcomLite);
  return instance.get(SHOPLO_URIS.SUMUP_LINK_TERMS_AND_CONDITIONS);
}

export function updateLogo(data, API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL);
  instance.defaults.baseURL = getBaseUrl(API_URL, EcomLite);
  return instance.patch(SHOPLO_URIS.SUMUP_LINK_LOGO, { logoUrl: data });
}

export function updateGeneralSettings(data, API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL);
  instance.defaults.baseURL = getBaseUrl(API_URL, EcomLite);
  return instance.patch(SHOPLO_URIS.SUMUP_LINK_GENERAL, data);
}

export function updateNotificationSettings(data, API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL);
  instance.defaults.baseURL = getBaseUrl(API_URL, EcomLite);
  return instance.patch(SHOPLO_URIS.SUMUP_LINK_NOTIFICATION, data);
}

export function updateShippingSettings(data, API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL);
  instance.defaults.baseURL = getStarterUrl(API_URL, EcomLite);
  return instance.patch(`${SHOPLO_URIS.SUMUP_LINK_SHIPPINGS}/${data.id}`, data);
}

export function updateTermsAndConditionsSettings(data, API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL);
  instance.defaults.baseURL = getBaseUrl(API_URL, EcomLite);
  return instance.put(SHOPLO_URIS.SUMUP_LINK_TERMS_AND_CONDITIONS, data);
}

export function updatePaymentSettings(data, API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL);
  instance.defaults.baseURL = getBaseUrl(API_URL, EcomLite);
  return instance.put(`${SHOPLO_URIS.SUMUP_LINK_PAYMENTS}/${data.id}`, {
    name: data.name,
  });
}

export function updateSocialMediaSettings(data, API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL);
  instance.defaults.baseURL = getBaseUrl(API_URL, EcomLite);
  return instance.patch(SHOPLO_URIS.SUMUP_LINK_SOCIAL_MEDIA, data);
}

export function checkMerchantStore(merchantCode, url) {
  return http.get(
    `${url}/api/sumup${SHOPLO_URIS.SUMUP_LINK_MERCHANT_STORE}?merchantCode=${merchantCode}`,
  );
}

export function getShopDetails(API_URL, EcomLite) {
  const instance = getHttpInstance(API_URL, EcomLite);
  return instance.get(
    `https://${EcomLite.storeName}.${API_URL}/api/v3/admin${SHOPLO_URIS.SHOP_DETAILS}`,
  );
}

export function getBillingPlans(SHOPLO_API, localeCode) {
  return http.get(
    `${SHOPLO_API}/front/billing/sumup-pricing?localeCode=${localeCode}`,
  );
}

export function getOrdersList(EcomLite, API_URL, limit, page) {
  const offset = (page - 1) * limit;
  const instance = getHttpInstance(API_URL, EcomLite);
  return instance.get(
    `https://${EcomLite.storeName}.${API_URL}/api/v3/admin${SHOPLO_URIS.ORDERS_LIST}?limit=${limit}&offset=${offset}`,
  );
}

export function getOrderDetails(EcomLite, API_URL, OrderID) {
  const instance = getHttpInstance(API_URL, EcomLite);
  return instance.get(
    `https://${EcomLite.storeName}.${API_URL}/api/v3/admin${SHOPLO_URIS.ORDER_DETAILS}/${OrderID}`,
  );
}
