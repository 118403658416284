import { createContext } from 'react';
import { noop } from 'lodash/fp';

// TODO: think about passing down $state directly.
const AppContext = createContext({
  navigate: noop,
  fallbackNavigate: window.location.assign,
  legacyStates: [],
});

export default AppContext;
