import URIS from 'constants/uris';
import http from 'services/http';

export const getLoans = () =>
  http.get(`${URIS.LENDING_GET_LOANS}`).then(({ data }) => data);

export const getFiles = () =>
  http.get(`${URIS.LENDING_GET_FILES}`).then(({ data }) => data);

export const applyToLoan = () =>
  http.post(`${URIS.LENDING_GET_LOANS}`).then(({ data }) => data);
