import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';

import { isProtectedRoute } from 'services/auth';
import { shouldShowGdprPage, isGdprRequired, isGdprRoute } from 'services/gdpr';
import { ROUTES } from 'components/Routes';
import { getLocale, addLocale } from 'components/Link';

export default class GdprRedirect extends Component {
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  };

  static defaultProps = {
    location: { search: '' },
  };

  state = {
    isFetching: true,
    shouldRedirectToGdpr: false,
    pathname: this.props.pathname,
  };

  shouldRedirectToGdpr = () =>
    Promise.all([shouldShowGdprPage(), isGdprRequired()]).then(
      ([showGdpr, isRequired]) => showGdpr && isRequired,
    );

  dispatchGdprCheck() {
    this.shouldRedirectToGdpr().then((shouldRedirectToGdpr) => {
      this.setState({
        isFetching: false,
        shouldRedirectToGdpr,
      });
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { pathname } = nextProps;

    if (nextProps.pathname !== prevState.pathname) {
      return { pathname, isFetching: true, shouldRedirectToGdpr: false };
    }

    return null;
  }

  componentDidMount() {
    if (isProtectedRoute(this.state.pathname)) {
      this.dispatchGdprCheck();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const isProtected = isProtectedRoute(this.state.pathname);

    const isDifferentLocation = prevState.pathname !== this.state.pathname;

    if (isProtected && isDifferentLocation) {
      this.dispatchGdprCheck();
    }
  }

  render() {
    const {
      location: { search = '' },
    } = this.props;
    const { pathname, shouldRedirectToGdpr, isFetching } = this.state;
    const isProtected = isProtectedRoute(pathname);
    const isGdpr = isGdprRoute(pathname);
    const locale = getLocale(pathname);

    if (isProtected && isFetching) {
      return null;
    }

    const redirectUrl =
      (isGdpr && !shouldRedirectToGdpr && addLocale(locale, ROUTES.OVERVIEW)) ||
      (!isGdpr && shouldRedirectToGdpr && addLocale(locale, ROUTES.GDPR));

    if (redirectUrl) {
      return <Redirect noThrow to={`${redirectUrl}${search}`} />;
    }

    return this.props.children;
  }
}
