import { createInstance } from '@optimizely/react-sdk';
import Cookies from 'js-cookie';
import uuid from 'uuid/v4';

import env from 'util/env';

export const optimizelyClient = createInstance({
  sdkKey: env('OPTIMIZELY_SDK_KEY'),
});

const OPTIMIZELY_USER_ID = 'OPTIMIZELY_USER_ID';
const ONE_YEAR = 365;

export function getOptimizelyUser() {
  const currentUserId = Cookies.get(OPTIMIZELY_USER_ID);

  if (currentUserId) {
    return { id: currentUserId };
  }

  const newUserId = uuid();

  Cookies.set(OPTIMIZELY_USER_ID, newUserId, { expires: ONE_YEAR });

  return { id: newUserId };
}
