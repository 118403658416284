import http from 'services/http';
import URIS from 'constants/uris';
import { trim } from 'util/transform';

getVoucherInfo.operation = 'READ';
getVoucherInfo.idFrom = (voucher) => voucher.code;
export function getVoucherInfo(voucher) {
  return http
    .get(`${URIS.VOUCHERS}/${trim(voucher)}`)
    .then(({ data }) => data)
    .catch(({ data }) => http.reject(data.error_code));
}
