import http from 'services/http';
import URIS from 'constants/uris';

import api from 'api';

export function getContentfulReadConfig({ merchantCode, lang, origin }) {
  return api.oneTimeTokens.getOneTimeToken().then((token) =>
    http.get(`${URIS.SUPPORT_READ}`, {
      headers: {
        merchant_code: merchantCode,
        Authorization: `Bearer ${token}`,
      },
      params: {
        lang,
        origin,
      },
    }),
  );
}

export function postReadingView(params) {
  return api.oneTimeTokens.getOneTimeToken().then((token) =>
    http.post(
      `${URIS.SUPPORT_READ}/${params.readingId}/views`,
      {
        ...params.data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  );
}

export function postArticleVotes(params) {
  return api.oneTimeTokens.getOneTimeToken().then((token) =>
    http.post(
      `${URIS.SUPPORT_READ}/${params.readingId}/votes`,
      {
        ...params.data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  );
}
