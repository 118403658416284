import URIS from 'constants/uris';
import http from 'services/http';

/**
 * @desc Fetches the payouts of the merchant.
 *
 * @param {number} merchantId - The ID of the merchant.
 *
 * @return {object} The data about the payouts returned by the API.
 *
 * @typedef {array} payouts
 *          The single element in the return object. It's an array of objects.
 *
 * @param {number} id
 * @param {string} target
 * @param {string} reference
 * @param {string} date
 * @param {number} payout_amount
 * @param {number} total_fee
 * @param {number} total_amount
 * @param {number} transactions_count
 */
getPayoutsHistory.operation = 'READ';
getPayoutsHistory.idFrom = 'ARGS';
export function getPayoutsHistory(params) {
  return http.get(URIS.PAYOUT_HISTORY, { params });
}

/**
 * @desc Fetches details for a given transaction.
 *
 * @param {number} id - The ID of the payout.
 *
 * @param {number} merchantId - The ID of the merchant.
 *
 * @return {object} The details data returned by the API.
 *
 * @typedef {array} transactions
 *          The single element in the return object. It's an array of objects.
 *
 * @param {string} id
 * @param {string} transaction_code
 * @param {string} card_type
 * @param {number} amount
 * @param {number} total_fee
 * @param {string} fee_percent
 * @param {string} timestamp
 */
getPayoutTransactions.operation = 'READ';
getPayoutTransactions.idFrom = 'ARGS';
export function getPayoutTransactions(id) {
  return http.get(URIS.PAYOUT_DETAILS.replace(':id', id));
}
