import URIS from 'constants/uris';
import http from 'services/http';

export function getRiskProfileConfig() {
  return http.get(URIS.RISK_PROFILE);
}

export function getVerificationProviderConfig(params) {
  return http.post(URIS.ID_VERIFICATIONS, params);
}

export function getVerificationProviderReport(verificationId, params) {
  return http.put(`${URIS.ID_VERIFICATIONS}/${verificationId}`, params);
}
