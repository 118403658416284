import styled from '@emotion/styled';
import { css } from '@emotion/core';

const LayoutContainer = styled.div(
  ({ vertical }) => css`
    display: flex;
    flex-direction: ${vertical ? 'column' : 'row'};
    flex: 1;
  `,
);

export default LayoutContainer;
