import React from 'react';
import PropTypes from 'prop-types';
import { TrackingRoot, TrackingView } from '@sumup/collector';

import { handleDispatch, normalizeTrackingView } from './CollectorService';

const Collector = ({ pathname = '', children }) => (
  <TrackingRoot name="dashboard" onDispatch={handleDispatch}>
    <TrackingView name={normalizeTrackingView(pathname)}>
      {children}
    </TrackingView>
  </TrackingRoot>
);

Collector.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Collector;
