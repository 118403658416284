import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

import Notification from 'components/Notification';

export const DEFAULT_TIMER = 5000;

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacings.kilo};
  `};
`;

const NotificationController = React.forwardRef(
  (
    {
      onRemove,
      id,
      dismissable = true,
      autoDismiss = true,
      timer = DEFAULT_TIMER,
      ...rest
    },
    ref,
  ) => {
    useEffect(() => {
      let timeout;

      if (dismissable && autoDismiss) {
        timeout = setTimeout(() => onRemove(id), timer);
      }

      return () => timeout && clearTimeout(timeout);
    }, [dismissable, autoDismiss, timer, id, onRemove]);

    return (
      <StyledWrapper ref={ref}>
        <Notification onRemove={() => onRemove(id)} {...rest} />
      </StyledWrapper>
    );
  },
);

NotificationController.displayName = 'NotificationController';

NotificationController.propTypes = {
  onRemove: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  dismissable: PropTypes.bool,
  autoDismiss: PropTypes.bool,
  timer: PropTypes.number,
};

export default NotificationController;
