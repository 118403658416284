import { get, getOr, includes } from 'lodash/fp';
import { injectScript } from 'services/script-injector';
import {
  getUserCountry,
  getUserEmail,
} from 'components/UserProvider/UserProviderService';
import getEnvFromUrl from 'services/get-env-from-url';

import {
  defaultCountries,
  COUNTRY_MAP,
  EN_LANGUAGE_MAP,
} from 'constants/localization';
import { getLanguageFromLocale } from 'util/localization';
import {
  getLiveAgentConfig,
  SELECTORS_TO_BE_HIDDEN,
} from './constants/live-agent-config';

export const getLiveAgentId = ({
  userCountry,
  userCurrentLanguageAndCountry,
}) => {
  const currentUrl = window.location.href;
  const env = getEnvFromUrl(currentUrl);
  const CONFIG = getLiveAgentConfig(env);
  const {
    LIVE_AGENT_BUTTON_COUNTRY,
    LIVE_AGENT_BUTTON_LOCALES,
    SUPPORTED_COUNTRIES,
  } = CONFIG;

  if (!includes(userCountry, SUPPORTED_COUNTRIES)) {
    return false;
  }

  if (LIVE_AGENT_BUTTON_LOCALES[userCurrentLanguageAndCountry]) {
    return LIVE_AGENT_BUTTON_LOCALES[userCurrentLanguageAndCountry];
  }

  if (LIVE_AGENT_BUTTON_COUNTRY[userCountry]) {
    return LIVE_AGENT_BUTTON_COUNTRY[userCountry];
  }

  return false;
};

const initLiveAgent = ({
  user,
  userEmail,
  userCountry,
  userCurrentLanguageAndCountry,
}) => {
  const currentUrl = window.location.href;
  const env = getEnvFromUrl(currentUrl);
  const CONFIG = getLiveAgentConfig(env);
  const { LIVE_AGENT_BUTTON_LOCALES } = CONFIG;
  const liveAgentId = getLiveAgentId({
    userCountry,
    userCurrentLanguageAndCountry,
  });

  const liveAgentLanguage = LIVE_AGENT_BUTTON_LOCALES[
    userCurrentLanguageAndCountry
  ]
    ? userCurrentLanguageAndCountry
    : defaultCountries[userCountry];
  const userLanguage = getLanguageFromLocale(liveAgentLanguage);
  // eslint-disable-next-line camelcase
  const { first_name, last_name } = getOr(
    {
      first_name: '',
      last_name: '',
    },
    'me.personal_profile',
    user,
  );

  if (!window.embedded_svc || !liveAgentId) {
    return;
  }

  window.embedded_svc.settings.displayHelpButton = false;
  window.embedded_svc.settings.defaultMinimizedText = 'Chat with us';
  window.embedded_svc.settings.language = liveAgentLanguage;
  window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
  window.embedded_svc.settings.entryFeature = 'LiveAgent';
  // connect old and new chat
  window.embedded_svc.settings.storageDomain = window.location.hostname;

  // Branding images:
  window.embedded_svc.settings.avatarImgURL = CONFIG.AVATAR_URL;
  window.embedded_svc.settings.waitingStateBackgroundImgURL =
    CONFIG.WAITING_STATE_BACKGROUND_IMG_URL;
  window.embedded_svc.settings.smallCompanyLogoImgURL =
    CONFIG.SMALL_COMPANY_LOGO_IMG_URL;
  window.embedded_svc.settings.extraPrechatFormDetails = [
    {
      label: 'First Name',
      name: 'FirstName',
      value: first_name,
      displayToAgent: true,
    },
    {
      label: 'Last Name',
      name: 'LastName',
      value: last_name,
      displayToAgent: true,
    },
    {
      label: 'Merchant Email',
      value: userEmail,
      displayToAgent: true,
    },
    {
      label: 'Origin',
      name: 'Origin__c',
      value: 'Dashboard',
      displayToAgent: true,
      transcriptFields: ['Origin__c'],
    },
    {
      label: 'Country',
      name: 'Country__c',
      value: COUNTRY_MAP[userCountry],
      displayToAgent: true,
      transcriptFields: ['Country__c'],
    },
    {
      label: 'Language',
      name: 'Language__c',
      value: EN_LANGUAGE_MAP[userLanguage],
      displayToAgent: true,
      transcriptFields: ['Language__c'],
    },
    {
      label: 'Merchant code',
      name: 'MerchantCode__c',
      value: user.me.merchant_profile.merchant_code,
      displayToAgent: true,
      transcriptFields: ['MerchantCode__c'],
    },
  ];
  window.embedded_svc.settings.extraPrechatInfo = [
    {
      entityName: 'Contact',
      saveToTranscript: 'ContactId',
      linkToEntityName: 'Case',
      linkToEntityField: 'ContactId',
      entityFieldMaps: [
        {
          label: 'Merchant Email',
          fieldName: 'Email',
          doFind: true,
          isExactMatch: true,
          doCreate: false,
        },
      ],
    },
  ];
  window.embedded_svc.init(
    CONFIG.SALESFORCE_URL,
    CONFIG.SALESFORCE_CHAT_URL,
    null,
    // ORG ID:
    CONFIG.ORIG_ID,
    'snapin_test',
    {
      baseLiveAgentContentURL: CONFIG.LIVE_AGENT_CONTENT_URL,
      deploymentId: CONFIG.DEPLOYMENT_ID,
      buttonId: liveAgentId,
      baseLiveAgentURL: CONFIG.LIVE_AGENT_URL,
      eswLiveAgentDevName: CONFIG.LIVE_AGENT_DEV_NAME,
    },
  );

  let agentMessageCounter = 0;
  const hideElements = () => {
    SELECTORS_TO_BE_HIDDEN.forEach((className) => {
      const element = document.querySelector(className);
      if (element) {
        element.style.display = 'none';
      }
    });
  };

  window.embedded_svc.addEventHandler('onAgentMessage', () => {
    if (agentMessageCounter === 0) {
      hideElements();
    }
    agentMessageCounter += 1;
  });
};

export const cleanUpLiveAgent = () => {
  const currentUrl = window.location.href;
  const env = getEnvFromUrl(currentUrl);
  const CONFIG = getLiveAgentConfig(env);
  const script = document.getElementById(CONFIG.CHAT_ID);
  const chatButton = document.getElementsByClassName(
    'embeddedServiceHelpButton',
  )[0];

  if (script) {
    script.remove();
  }

  if (chatButton) {
    chatButton.remove();
  }
};

export const createLiveAgent = ({ user, id }) => {
  const currentUrl = window.location.href;
  const env = getEnvFromUrl(currentUrl);
  const CONFIG = getLiveAgentConfig(env);
  const locale = get('me.merchant_profile.locale', user);
  const userEmail = getUserEmail(user);
  const userCountry = getUserCountry(user);
  const userCurrentLanguage = getLanguageFromLocale(locale);
  const userCurrentLanguageAndCountry = `${userCurrentLanguage}-${userCountry}`;

  if (
    !user ||
    !getLiveAgentId({
      userCountry,
      userCurrentLanguageAndCountry,
    })
  ) {
    return;
  }

  const scriptOptions = { ...CONFIG.SCRIPT_INJECTOR_CONFIG, id };

  injectScript(scriptOptions).then(() =>
    initLiveAgent({
      user,
      userEmail,
      userCountry,
      userCurrentLanguage,
      userCurrentLanguageAndCountry,
    }),
  );
};
