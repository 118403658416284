import { compose, get } from 'lodash/fp';
import { Observable } from 'rxjs/Observable';

import api from '.';

const toRxJSObservable = ({ subscribe }) =>
  Observable.create((observer) =>
    subscribe(observer.next.bind(observer), observer.error.bind(observer)),
  );

const getObservable = compose(
  // Normalize it to RxJS
  toRxJSObservable,
  // Create an observable for the API
  (apiFn) => apiFn.createObservable(),
  // Get specific READ API,
  (apiKey) => get(apiKey, api),
);

export default getObservable;
