import React, { Component } from 'react';
import { hoistStatics, wrapDisplayName } from 'recompose';

import NotificationContext from './NotificationContext';

const withNotification = (WrappedComponent) => {
  class WithNotification extends Component {
    WrappedComponent = WrappedComponent;

    render() {
      return (
        <NotificationContext.Consumer>
          {({ addNotification }) => (
            <WrappedComponent
              {...this.props}
              addNotification={addNotification}
            />
          )}
        </NotificationContext.Consumer>
      );
    }
  }

  WithNotification.displayName = wrapDisplayName(
    WrappedComponent,
    'withNotification',
  );

  return WithNotification;
};

export default hoistStatics(withNotification);
