import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { stripUnit } from 'polished';

import { HEADER_HEIGHT } from 'components/PageHeader/PageHeaderConstants';
import { CLIENT_INFO } from 'util/user-agent';
import { hasHeader } from './PaddingContainerService';

const StyledWrapper = styled.div`
  ${({ theme, hasPageHeader }) =>
    css`
      margin-top: ${hasPageHeader
        ? HEADER_HEIGHT.MOBILE + stripUnit(theme.spacings.giga)
        : 0}px;

      ${theme.mq.giga} {
        margin-top: 0;
      }
    `};
`;

const PaddingContainer = ({ pathname }) => (
  <StyledWrapper
    hasPageHeader={hasHeader(pathname) && !CLIENT_INFO.isWebView}
  />
);

PaddingContainer.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default PaddingContainer;
