import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash/fp';
import { I18n } from 'react-i18next';
import { hoistStatics, wrapDisplayName } from 'recompose';

import { setMomentLocale } from 'services/i18n';
import { isValidRoute, addLocale } from 'components/Link';
import { navigate } from '@reach/router';

const withI18n = (WrappedComponent) => {
  class WithI18n extends Component {
    static propTypes = {
      ref: PropTypes.func,
    };

    WrappedComponent = WrappedComponent;

    state = {
      isLoading: false,
    };

    handleToggleLoading = () => {
      this.setState(({ isLoading }) => ({ isLoading: !isLoading }));
    };

    handleUpdateLocation = (locale) => {
      const localizedPath = addLocale(locale, window.location.pathname);

      if (isValidRoute(window.location.pathname)) {
        return navigate(`${localizedPath}${window.location.search}`);
      }

      return window.location.assign(
        `${localizedPath}${window.location.search}`,
      );
    };

    render() {
      const { isLoading } = this.state;
      const { ref } = this.props;

      return (
        <I18n>
          {(t, { i18n }) =>
            i18n.language && (
              <WrappedComponent
                translate={t}
                locale={i18n.language}
                isLoadingTranslations={isLoading}
                ref={ref}
                changeLocale={(locale) => {
                  this.handleToggleLoading();
                  i18n.changeLanguage(locale, () => {
                    setMomentLocale(locale, () => {
                      this.handleToggleLoading();
                      this.handleUpdateLocation(locale);
                    });
                  });
                }}
                {...omit(['ref'], this.props)}
              />
            )
          }
        </I18n>
      );
    }
  }

  WithI18n.displayName = wrapDisplayName(WrappedComponent, 'withI18n');

  return WithI18n;
};

export default hoistStatics(withI18n);
