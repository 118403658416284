import ROUTES from 'components/Routes/RoutesConstants';

export const UNPROTECTED_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.RESET_PASSWORD,
  ROUTES.RESET_PASSWORD_LEGACY,
  ROUTES.LOGOUT,
  ROUTES.SIGNUP_CREATE,
  ROUTES.CREATE_ACCOUNT_APPS,
  ROUTES.START,
];
