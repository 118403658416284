import URIS from 'constants/uris';
import http from 'services/http';
import _ from 'lodash';

getTransactionsSearch.operation = 'READ';
getTransactionsSearch.idFrom = 'ARGS';
export function getTransactionsSearch(params) {
  if (_.isEmpty(params)) {
    return Promise.resolve(null);
  }

  return http.get(`${URIS.TRANSACTION_SEARCH}`, { params });
}

getTransactionsHistory.operation = 'READ';
getTransactionsHistory.idFrom = 'ARGS';
export function getTransactionsHistory(params) {
  if (_.isEmpty(params)) {
    return Promise.resolve(null);
  }

  return http.get(`${URIS.TRANSACTION_HISTORY}`, { params });
}

/**
 * @desc Fetches details for a given transaction.
 *
 * @param {string} transactionId - The ID of the transaction item.
 *
 * @return {object} The details data returned by the API.
 *
 */
getTransactionAccounts.operation = 'READ';
getTransactionAccounts.idFrom = 'ARGS';
export function getTransactionAccounts(params) {
  return http
    .get(`${URIS.TRANSACTION_ACCOUNTS}`, { params })
    .then(({ data }) => data);
}

export function sendReceipt(id, params) {
  const receiptUrl = `${URIS.RECEIPTS}/${id}`;
  return http.post(receiptUrl, params);
}
