import http from 'services/http';
import URIS from 'constants/uris';
import { sortBy, property } from 'lodash';

getProducts.operation = 'READ';
export function getProducts(country) {
  return http
    .get(`${URIS.SHOP_PRODUCTS}?country=${country}`)
    .then(({ data }) => sortBy(data, property('title')));
}

export function deleteProductFromOrder(orderId, orderItemId) {
  return http.delete(`${URIS.SHOP_ORDERS}/${orderId}/products/${orderItemId}`);
}

addProductToOrder.operation = 'UPDATE';
export function addProductToOrder(orderId, products) {
  return http.put(`${URIS.SHOP_ORDERS}/${orderId}`, products);
}
