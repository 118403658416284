import URIS from 'constants/uris';
import http from 'services/http';

getOverview.operation = 'READ';
getOverview.idFrom = 'ARGS';
export function getOverview(params) {
  return http.get(URIS.OVERVIEW, { params });
}

refreshData.operation = 'NO_OPERATION';
refreshData.invalidates = ['getOverview'];
export function refreshData() {
  return Promise.resolve(true);
}
