import i18next from 'i18next';
import xhrBackend from 'i18next-xhr-backend';
import moment from 'moment';
import { compose, identity, noop } from 'lodash/fp';

import logger from 'services/logger';
import http from 'services/http';
import {
  supportedLocales,
  DEFAULT_LOCALE,
  momentLocales,
  defaultMomentLocale,
} from 'constants/localization';
import { getValidLocale } from 'util/localization';

const getBrowserLocale = () =>
  window.navigator.language ||
  window.navigator.userLanguage ||
  window.navigator.browserLanguage ||
  window.navigator.systemLanguage;

const loadTranslations = (url, options, callback) => {
  // FIXME: The cache here does not work because axios does
  //        not support client-side caching out of the box.
  //        Luckily browsers cache the translation files.
  http
    .get(url, { cache: true })
    .then(({ data, status }) => {
      callback(data, { status });
    })
    .catch(({ status }) => callback(null, { status }));
};

const CONFIG = {
  debug: false, // logs debug information when set to true.
  fallbackLng: DEFAULT_LOCALE,
  /**
   * The load option is tricky. By default i18next will load both the language
   * and the locale. For example, when passed the locale en-GB it will try
   * to load both the en-GB.json AND the en.json and dev.json files, causing additional
   * traffic at best and 404 errors in most cases. Setting 'currentOnly'
   * ensures it will only download the specified locale, i.e., en-GB.json.
   * https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
   */
  load: 'currentOnly',
  whitelist: supportedLocales,
  backend: {
    loadPath: '/locales/{{lng}}.json',
    parse: identity,
    ajax: loadTranslations,
  },
  /**
   * i18next has some special options for React.
   * https://react.i18next.com/v9/i18next-instance
   */
  react: {
    wait: true, // Only render children after loading is complete.
  },
};

const getLocalizationLocale = compose(
  getValidLocale({ strictLocales: true }),
  (locale) => locale || getBrowserLocale(),
);

export const setMomentLocale = (localizationLocale, callback = noop) => {
  const momentLocale = momentLocales[localizationLocale];
  if (!momentLocale) {
    logger.warning(
      `i18n: missing moment locale configuration for ${localizationLocale}`,
    );
    callback();
    return;
  }

  moment.locale(momentLocale);
  if (momentLocale !== defaultMomentLocale) {
    import(`moment/locale/${momentLocale}.js`)
      .catch((err) =>
        logger.error(err, `i18n: unable to load moment locale ${momentLocale}`),
      )
      .then(callback);
  } else {
    callback();
  }
};

/**
 * Using this singleton allows us to inject fresh i18n instances
 * in unit tests without having to export a reset function from this
 * module.
 * */
const i18nSingleton = { i18n: null };

export const createI18n = (locale, singleton = i18nSingleton) => {
  const localizationLocale = getLocalizationLocale(locale);
  if (!singleton.i18n) {
    try {
      singleton.i18n = i18next
        .use(xhrBackend)
        .init({ ...CONFIG, lng: localizationLocale }, (err) => {
          if (err) {
            throw err;
          }

          setMomentLocale(localizationLocale);
        });
    } catch (err) {
      logger.error(
        err,
        `i18n: unable to initialize service with ${localizationLocale}`,
      );

      return null;
    }
  }

  const { language: previousLng } = singleton.i18n;

  if (previousLng && previousLng !== localizationLocale) {
    singleton.i18n.changeLanguage(localizationLocale);
    setMomentLocale(localizationLocale);
  }

  return singleton.i18n;
};
