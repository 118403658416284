import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePageViewTrigger, usePageActiveTrigger } from '@sumup/collector';

import { sendPageview } from 'services/analytics';

const PageviewHandler = ({ href }) => {
  const dispatch = usePageViewTrigger();
  usePageActiveTrigger();

  useEffect(() => {
    sendPageview();
    dispatch();
  }, [href]);

  return null;
};

PageviewHandler.propTypes = {
  href: PropTypes.string,
};

PageviewHandler.defaultProps = {
  href: '',
};

export default PageviewHandler;
