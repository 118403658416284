import {
  flow,
  fromPairs,
  get,
  getOr,
  isEmpty,
  keys,
  mapValues,
  reduce,
  toPairs,
  values,
  zip,
  pick,
  join,
  isEqual,
} from 'lodash/fp';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/map';

import { getObservable } from 'api';
import logger from 'services/logger';
import { CURRENCIES } from 'constants/localization';
import ACCOUNT_TYPES from 'constants/account-types';

const mapMe = ({ data } = {}) =>
  isEmpty(data)
    ? undefined
    : {
        ...data,
        country: get('merchant_profile.country', data),
      };

const mapPermissions = ({ data } = {}) => (isEmpty(data) ? undefined : data);

const mapRiskProfile = ({ data } = {}) =>
  isEmpty(data)
    ? undefined
    : {
        personalProfileComplete:
          get('personal_profile.complete', data) || false,
        merchantProfileComplete:
          get('merchant_profile.complete', data) || false,
        bankAccountDetailsComplete: get('bank_account.complete', data) || false,
        bankAccountVerified: get('bank_account_check', data) === 'PASSED',
        isActive: get('state', data) === 'ACTIVE',
        isSuspended: get('state', data) === 'SUSPENDED',
        isClosed: get('state', data) === 'CLOSED',
        documentsVerified: !getOr(false, 'document_upload_notification', data),
      };

const mapSettings = ({ data } = {}) => (isEmpty(data) ? undefined : data);

const USER_MAP = {
  ME: 'me',
  RISK_PROFILE: 'accountState',
  SETTINGS: 'settings',
  PERMISSIONS: 'permissions',
};

const APIS = {
  [USER_MAP.ME]: 'me.getMe',
  [USER_MAP.RISK_PROFILE]: 'riskProfile.getRiskProfile',
  [USER_MAP.SETTINGS]: 'merchantProfileSettings.getMerchantProfileSettings',
  [USER_MAP.PERMISSIONS]: 'permissions.getPermissions',
};

const MAPS = {
  [USER_MAP.ME]: mapMe,
  [USER_MAP.RISK_PROFILE]: mapRiskProfile,
  [USER_MAP.SETTINGS]: mapSettings,
  [USER_MAP.PERMISSIONS]: mapPermissions,
};

const applyMaps = flow(
  toPairs,
  reduce(
    (mapped, [name, obs]) => ({ ...mapped, [name]: obs.map(MAPS[name]) }),
    {},
  ),
);

const combine = (observableMap) => {
  const names = keys(observableMap);
  const observables = values(observableMap);
  return Observable.combineLatest(observables, (...args) =>
    flow(zip(names), fromPairs)(args),
  );
};

export const getUserObservable = () =>
  flow(mapValues(getObservable), applyMaps, combine)(APIS);

export const WARNING_WAIT_TIME = 3000;

export const setSlowLoadWarningTimeout = () => {
  const timeoutId = setTimeout(() => {
    logger.warning(
      `UserProvider: failed to load user profile within ${WARNING_WAIT_TIME /
        1000}s.`,
    );
  }, WARNING_WAIT_TIME);

  return () => {
    clearTimeout(timeoutId);
  };
};

export const getPermissions = get('me.permissions');
export const getFullTransactionHistoryView = flow(
  getPermissions,
  ({ full_transaction_history_view: permission }) => permission,
);

/** Account attributes */
export const getUserEmail = get('me.account.username');
export const getAccountType = get('me.account.type');

/** Merchant profile attributes */
export const getUserCountry = get('me.merchant_profile.country');
export const getUserLocale = get('me.merchant_profile.locale');
export const getUserCurrency = (user) =>
  CURRENCIES[get('me.merchant_profile.country', user)] || CURRENCIES.default;

export const getMerchantCode = get('me.merchant_profile.merchant_code');
export const getCompanyName = get('me.merchant_profile.company_name');
export const getCompanyRegistrationNumber = get(
  'me.merchant_profile.company_registration_number',
);
export const isSoleTrader = get('me.merchant_profile.legal_type.sole_trader');
export const getLegalType = get('me.merchant_profile.legal_type');
export const getMerchantCategoryCode = get(
  'me.merchant_profile.merchant_category_code',
);

/** Personal profile attributes */
export const getNationalId = get('me.personal_profile.national_id');
export const composeUserName = flow(
  pick(['first_name', 'last_name']),
  values,
  join(' '),
);

export const getUserName = (user) => {
  const personalProfile = get('me.personal_profile', user);

  if (!personalProfile) {
    return '';
  }

  return composeUserName(personalProfile);
};

export const isSubaccount = flow(
  getAccountType,
  isEqual(ACCOUNT_TYPES.SUBACCOUNT),
);

export const hasFilledAccountDetails = (user) =>
  get('accountState.merchantProfileComplete', user) &&
  get('accountState.personalProfileComplete', user) &&
  get('accountState.bankAccountDetailsComplete', user);

let merchantCode;
// These helpers set/clear support usage outside of the React flow
export const setGlobalMerchantCode = (code) => {
  merchantCode = code;
};

export const clearGlobalMerchantCode = () => {
  merchantCode = undefined;
};

export const getGlobalMerchantCode = () => merchantCode;
