import URIS from 'constants/uris';
import http from 'services/http';

getEmployees.operation = 'READ';
export function getEmployees() {
  const config = {
    params: { include: ['permissions', 'operators'] },
  };
  return http.get(URIS.SUBACCOUNTS, config).then(({ data }) => data);
}

createEmployee.invalidates = ['getEmployees'];
export function createEmployee(params) {
  return http.post(URIS.SUBACCOUNTS, params);
}

updateEmployeePermissions.invalidates = ['getEmployees'];
export function updateEmployeePermissions({ id, permissions }) {
  return http.put(`${URIS.SUBACCOUNTS}/${id}/permissions`, permissions);
}

export function updateEmployeePassword({ id, password }) {
  return http.put(`${URIS.SUBACCOUNTS}/${id}/reset`, { password });
}

deleteEmployee.invalidates = ['getEmployees'];
export function deleteEmployee({ id }) {
  return http.delete(`${URIS.SUBACCOUNTS}/${id}`);
}
