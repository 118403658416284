import http from 'services/http';
import URIS from 'constants/uris';

getFeatureToggle.operation = 'READ';
getFeatureToggle.idFrom = (toggle) => toggle.name;
export function getFeatureToggle(pattern, country = '', merchantCode = '') {
  let url = `${URIS.FEATURE_TOGGLES}?name=${pattern}`;
  if (country) {
    url += `&country=${country}`;
  }

  if (merchantCode) {
    url += `&merchant_code=${merchantCode}`;
  }

  return http.get(url).then(({ data }) => data);
}
