import { build } from 'ladda-cache';
import { observable } from 'ladda-observable';

import * as activationsApi from './activations';
import * as appSettingsApi from './app-settings';
import * as authApi from './authentication';
import * as balanceApi from './balance';
import * as banksApi from './banks';
import * as boletosApi from './boletos';
import * as clientProjectsApi from './client-projects';
import * as countriesApi from './countries';
import * as documentsApi from './documents';
import * as documentSettingsApi from './document-settings';
import * as employeesApi from './employees';
import * as devicesApi from './devices';
import * as featureTogglesApi from './feature-toggles';
import * as featureTogglesManyApi from './feature-toggles-many';
import * as invoicingApi from './invoicing';
import * as legalTypesApi from './legal-types';
import * as logoutApi from './logout';
import * as meApi from './me';
import * as merchantCategoriesApi from './merchant-categories';
import * as merchantProfileApi from './merchant-profile';
import * as merchantProfileSettingsApi from './merchant-profile-settings';
import * as mobileVerificationsApi from './mobile-verifications';
import * as notificationsApi from './notifications';
import * as oneTimeTokensApi from './one-time-tokens';
import * as ordersApi from './orders';
import * as carriersApi from './carriers';
import * as overviewApi from './overview';
import * as partnershipsApi from './partnerships';
import * as passwordsApi from './passwords';
import * as paymentTypesApi from './payment-types';
import * as payoutsApi from './payouts';
import * as permissionsApi from './permissions';
import * as personalProfileApi from './personal-profile';
import * as posMerchantApi from './pos-merchant';
import * as prepaidCardsApi from './prepaid-cards';
import * as productsApi from './products';
import * as supportApi from './support';
import * as refundsApi from './refunds';
import * as registrationsApi from './registrations';
import * as riskProfileApi from './risk-profile';
import * as sessionInfoApi from './session-info';
import * as settlementReportsApi from './settlement-reports';
import * as subscriptionsApi from './subscriptions';
import * as virtualTerminalApi from './virtual-terminal';
import * as transactionDetailsApi from './transaction-details';
import * as transactionsHistoryApi from './transactions-history';
import * as userEventsApi from './user-events';
import * as usersApi from './users';
import * as bankAccountValidationsApi from './validations/bank-account';
import * as merchantProfileValidationsApi from './validations/merchant-profile';
import * as personalProfileValidationsApi from './validations/personal-profile';
import * as shippingOrderValidationsApi from './validations/shipping-orders';
import * as doingBusinessAsValidationsApi from './validations/doing-business-as';
import * as userValidationsApi from './validations/users';
import * as dynamicDescriptorValidationsApi from './validations/dynamic-descriptor';
import * as vouchersApi from './vouchers';
import * as referralsApi from './referrals';
import * as gdprRulesApi from './gdpr-rules';
import * as gdprConsentsApi from './gdpr-consents';
import * as catalogCategoriesApi from './catalog-categories';
import * as catalogProductsApi from './catalog-products';
import * as catalogTaxRatesApi from './catalog-tax-rates';
import * as catalogImages from './catalog-images';
import * as catalogImagesUploadPolicy from './catalog-images-upload-policy';
import * as cardApi from './card';
import * as payoutsHistoryApi from './payout-history';
import * as paymentApi from './payment';
import * as dynamicDescriptorApi from './dynamic-descriptor';
import * as ecomLiteApi from './ecom-lite';
import * as customisableReceiptsApi from './customisable-receipts';
import * as providerVerificationApi from './provider-verification';
import * as lendingApi from './lending';
import * as reportsApi from './reports';

/**
 * If you add an API that requires authentication, make sure you
 * add it to this array. This ensures the API gets invalidated
 * on login and logout.
 */

const authInvalidationApis = [
  'activations',
  'appSettings',
  'auth',
  'balance',
  'banks',
  'card',
  'catalogCategories',
  'catalogProducts',
  'catalogTaxRates',
  'clientProjects',
  'countries',
  'devices',
  'documents',
  'documentSettings',
  'employees',
  'featureToggles',
  'gdprConsents',
  'gdprRules',
  'legalTypes',
  'logout',
  'me',
  'merchantCategories',
  'merchantProfile',
  'merchantProfileSettings',
  'mobileVerifications',
  'notifications',
  'orders',
  'overview',
  'partnerships',
  'passwords',
  'paymentTypes',
  'payouts',
  'permissions',
  'personalProfile',
  'posMerchant',
  'prepaidCards',
  'products',
  'support',
  'referrals',
  'refunds',
  'registrations',
  'riskProfile',
  'sessionInfo',
  'settlementReports',
  'subscriptions',
  'virtualTerminal',
  'transactionDetails',
  'transactionsHistory',
  'payoutsHistory',
  'userEvents',
  'users',
  'vouchers',
  'payment',
  'providerVerification',
  'lending',
];

const config = {
  activations: {
    api: activationsApi,
    invalidates: ['riskProfile', 'mobileVerifications', 'me'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  appSettings: {
    api: appSettingsApi,
  },
  auth: {
    api: authApi,
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
    invalidates: authInvalidationApis,
  },
  balance: {
    api: balanceApi,
  },
  banks: {
    api: banksApi,
    invalidates: ['me', 'riskProfile', 'banks'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  boletos: {
    api: boletosApi,
  },
  clientProjects: {
    api: clientProjectsApi,
  },
  countries: {
    api: countriesApi,
  },
  documents: {
    api: documentsApi,
  },
  documentSettings: {
    api: documentSettingsApi,
  },
  employees: {
    api: employeesApi,
    invalidates: ['employees'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  devices: {
    api: devicesApi,
  },
  featureToggles: {
    api: featureTogglesApi,
  },
  featureTogglesMany: {
    api: featureTogglesManyApi,
  },
  invoicing: {
    api: invoicingApi,
  },
  legalTypes: {
    api: legalTypesApi,
  },
  logout: {
    api: logoutApi,
    invalidates: authInvalidationApis,
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  me: {
    api: meApi,
  },
  merchantCategories: {
    api: merchantCategoriesApi,
  },
  merchantProfile: {
    api: merchantProfileApi,
    invalidates: ['riskProfile', 'registrations', 'me'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  merchantProfileSettings: {
    api: merchantProfileSettingsApi,
  },
  prepaidCards: {
    api: prepaidCardsApi,
    invalidates: ['riskProfile', 'merchantProfileSettings'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  mobileVerifications: {
    api: mobileVerificationsApi,
  },
  notifications: {
    api: notificationsApi,
    invalidates: ['riskProfile', 'registrations', 'me'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  orders: {
    api: ordersApi,
    invalidates: ['orders', 'riskProfile', 'me'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  oneTimeTokens: {
    api: oneTimeTokensApi,
  },
  carriers: {
    api: carriersApi,
  },
  overview: {
    api: overviewApi,
  },
  partnerships: {
    api: partnershipsApi,
  },
  passwords: {
    api: passwordsApi,
  },
  paymentTypes: {
    api: paymentTypesApi,
  },
  payouts: {
    api: payoutsApi,
    invalidates: ['balance'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  permissions: {
    api: permissionsApi,
  },
  personalProfile: {
    api: personalProfileApi,
    invalidates: ['riskProfile', 'registrations', 'me'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  posMerchant: {
    api: posMerchantApi,
  },
  products: {
    api: productsApi,
    invalidates: ['orders'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  subscriptions: {
    api: subscriptionsApi,
    invalidates: ['subscriptions'],
    invalidatesOn: ['READ'],
  },
  support: {
    api: supportApi,
  },
  referrals: {
    api: referralsApi,
  },
  refunds: {
    api: refundsApi,
    invalidates: ['transactionsHistory', 'transactionDetails'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  registrations: {
    api: registrationsApi,
    invalidates: ['personalProfile', 'merchantProfile', 'me'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  riskProfile: {
    api: riskProfileApi,
  },
  sessionInfo: {
    api: sessionInfoApi,
  },
  settlementReports: {
    api: settlementReportsApi,
  },
  virtualTerminal: {
    api: virtualTerminalApi,
    invalidates: ['transactionsHistory'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE', 'NO_OPERATION'],
  },
  transactionDetails: {
    api: transactionDetailsApi,
  },
  transactionsHistory: {
    api: transactionsHistoryApi,
  },
  payoutsHistory: {
    api: payoutsHistoryApi,
  },
  userEvents: {
    api: userEventsApi,
  },
  users: {
    api: usersApi,
  },
  bankAccountValidations: {
    api: bankAccountValidationsApi,
    ttl: 3600,
  },
  merchantProfileValidations: {
    api: merchantProfileValidationsApi,
    ttl: 3600,
  },
  personalProfileValidations: {
    api: personalProfileValidationsApi,
    ttl: 3600,
  },
  shippingOrderValidations: {
    api: shippingOrderValidationsApi,
    ttl: 3600,
  },
  userValidations: {
    api: userValidationsApi,
    ttl: 3600,
  },
  dynamicDescriptorValidations: {
    api: dynamicDescriptorValidationsApi,
    ttl: 3600,
  },
  doingBusinessAsValidations: {
    api: doingBusinessAsValidationsApi,
    ttl: 3600,
  },
  vouchers: {
    api: vouchersApi,
  },
  gdprConsents: {
    api: gdprConsentsApi,
    invalidates: ['riskProfile'],
    invalidatesOn: ['CREATE', 'UPDATE'],
  },
  gdprRules: {
    api: gdprRulesApi,
  },
  catalogCategories: {
    api: catalogCategoriesApi,
    invalidates: ['catalogProducts'],
    invalidatesOn: ['DELETE'],
  },
  catalogProducts: {
    api: catalogProductsApi,
    invalidates: ['catalogCategories'],
    invalidatesOn: ['CREATE', 'UPDATE', 'DELETE'],
  },
  catalogTaxRates: {
    api: catalogTaxRatesApi,
  },
  catalogImages: {
    api: catalogImages,
  },
  catalogImagesUploadPolicy: {
    api: catalogImagesUploadPolicy,
  },
  card: {
    api: cardApi,
  },
  payment: {
    api: paymentApi,
  },
  dynamicDescriptor: {
    api: dynamicDescriptorApi,
  },
  ecomLite: {
    api: ecomLiteApi,
  },
  customisableReceipts: {
    api: customisableReceiptsApi,
  },
  providerVerification: {
    api: providerVerificationApi,
  },
  lending: {
    api: lendingApi,
  },
  reports: {
    api: reportsApi,
  },
};

export { default as getObservable } from './get-observable';

export default build(config, [observable()]);
