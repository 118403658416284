import URIS from 'constants/uris';
import http from 'services/http';

export const uploadImage = (formData, imageServiceHost, uploadPolicy) =>
  http
    .post(`${imageServiceHost}${URIS.CATALOG_IMAGES}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Upload-Policy': uploadPolicy,
      },
    })
    .then(({ data }) => data);
