import { lifecycle } from 'recompose';

import { connectWebView, defaultBridgeHandler } from 'services/webview-bridge';

const WebviewHandler = ({ children }) => children;

const withLifecycle = lifecycle({
  componentDidMount() {
    connectWebView().then(defaultBridgeHandler);
  },
});

export default withLifecycle(WebviewHandler);
