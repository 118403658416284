/**
 * @DEPRECATED please use util/env as env('ENV')
 * Keep in mind the returned values aren't mapped to development, staging and
 * production but rather theta, stage and live
 */
import { includes } from 'lodash/fp';

const getEnvFromUrl = (currentUrl) => {
  if (includes('me.sumup.com', currentUrl)) {
    return 'production';
  }
  if (includes('dashboard.sam-app.ro', currentUrl)) {
    return 'staging';
  }
  return 'development';
};

export default getEnvFromUrl;
