import URIS from 'constants/uris';
import http from 'services/http';

export function getPartnershipProfile() {
  return http.get(URIS.PARTNERSHIP).then(({ data }) => data);
}

updatePartnership.invalidates = ['getPartnershipProfile'];
export function updatePartnership(params) {
  return http.put(URIS.PARTNERSHIP, params);
}
