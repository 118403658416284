export const restrictedShippingCountries = ['US', 'CL', 'PL', 'CH', 'BR', 'NO'];
export const euCountries = [
  'AT',
  'BE',
  'GB',
  'IE',
  'FR',
  'CH',
  'PT',
  'DE',
  'IT',
  'ES',
  'NL',
  'PL',
  'SE',
];

/*
 * XXX: Countries in Silent mode only
 */
export const newEuCountries = [
  'BG',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'GR',
  'HU',
  'LV',
  'LT',
  'LU',
  'MT',
  'NO',
  'RO',
  'SK',
  'SI',
  'HR',
];

export const nonEuCountries = ['BR', 'US', 'CL', 'CO'];

export const SINGLE_LOCALE_COUNTRIES = ['BR', 'US', 'CL', 'CO'];

export const allCountries = [
  ...euCountries,
  ...newEuCountries,
  ...nonEuCountries,
];

export const supportedLocales = [
  'bg-BG',
  'cs-CZ',
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'de-LU',
  'el-CY',
  'el-GR',
  'en-GB',
  'en-IE',
  'en-MT',
  'en-US',
  'es-CL',
  'es-CO',
  'es-ES',
  'et-EE',
  'fi-FI',
  'fr-BE',
  'fr-CH',
  'fr-FR',
  'fr-LU',
  'hr-HR',
  'hu-HU',
  'it-CH',
  'it-IT',
  'lt-LT',
  'lv-LV',
  'nb-NO',
  'nl-BE',
  'nl-NL',
  'pt-BR',
  'pt-PT',
  'pl-PL',
  'ro-RO',
  'sk-SK',
  'sl-SI',
  'sv-SE',
];

export const defaultLanguages = {
  bg: 'bg-BG',
  cs: 'cs-CZ',
  da: 'da-DK',
  de: 'de-DE',
  el: 'el-GR',
  en: 'en-GB',
  es: 'es-ES',
  et: 'et-EE',
  fi: 'fi-FI',
  fr: 'fr-FR',
  hr: 'hr-HR',
  hu: 'hu-HU',
  it: 'it-IT',
  lt: 'lt-LT',
  lv: 'lv-LV',
  nb: 'nb-NO',
  nl: 'nl-NL',
  pl: 'pl-PL',
  pt: 'pt-BR',
  ro: 'ro-RO',
  sk: 'sk-SK',
  sl: 'sl-SI',
  sv: 'sv-SE',
};

export const defaultCountries = {
  AT: 'de-AT',
  BE: 'fr-BE',
  BG: 'bg-BG',
  BR: 'pt-BR',
  CH: 'de-CH',
  CL: 'es-CL',
  CO: 'es-CO',
  CY: 'el-CY',
  CZ: 'cs-CZ',
  DA: 'da-DK',
  DE: 'de-DE',
  DK: 'da-DK',
  EE: 'et-EE',
  ES: 'es-ES',
  FI: 'fi-FI',
  FR: 'fr-FR',
  GB: 'en-GB',
  GR: 'el-GR',
  HU: 'hu-HU',
  HR: 'hr-HR',
  IE: 'en-IE',
  IT: 'it-IT',
  LT: 'lt-LT',
  LU: 'fr-LU',
  LV: 'lv-LV',
  MT: 'en-MT',
  NL: 'nl-NL',
  NO: 'nb-NO',
  PL: 'pl-PL',
  PT: 'pt-PT',
  RO: 'ro-RO',
  SE: 'sv-SE',
  SI: 'sl-SI',
  SK: 'sk-SK',
  US: 'en-US',
};

export const languageNames = {
  bg: 'Български',
  cs: 'Čeština',
  da: 'Dansk',
  de: 'Deutsch',
  el: 'Ελληνικά',
  en: 'English',
  es: 'Español',
  et: 'Eesti',
  fi: 'Suomi',
  fr: 'Français',
  hr: 'Hrvatski',
  hu: 'Magyar',
  it: 'Italiano',
  lt: 'Lietuviškai',
  lv: 'Latviešu valoda',
  nb: 'Norsk',
  nl: 'Nederlands',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Română',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  sv: 'Svenska',
};

export const CURRENCIES = {
  BR: 'BRL',
  PL: 'PLN',
  GB: 'GBP',
  CH: 'CHF',
  SE: 'SEK',
  US: 'USD',
  CL: 'CLP',
  BG: 'BGN',
  CZ: 'CZK',
  DK: 'DKK',
  HU: 'HUF',
  NO: 'NOK',
  RO: 'RON',
  HR: 'HRK',
  CO: 'COP',
  default: 'EUR',
};

export const COUNTRY_MAP = {
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  BR: 'Brazil',
  CH: 'Switzerland',
  CL: 'Chile',
  CO: 'Colombia',
  CN: 'China',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  EE: 'Estonia',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GB: 'United Kingdom',
  GR: 'Greece',
  HU: 'Hungary',
  HR: 'Croatia',
  IE: 'Ireland',
  IT: 'Italy',
  LT: 'Lithuania',
  LU: 'Luxemberg',
  NL: 'Netherlands',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SE: 'Sweden',
  SI: 'Slovenia',
  SK: 'Slovakia',
  US: 'United States',
};

export const EN_LANGUAGE_MAP = {
  bg: 'Bulgarian',
  cs: 'Czech',
  da: 'Danish',
  de: 'German',
  el: 'Greek',
  en: 'English',
  es: 'Spanish',
  et: 'Estonian',
  fi: 'Finnish',
  fr: 'French',
  hr: 'Croatian',
  hu: 'Hungarian',
  it: 'Italian',
  lt: 'Lithuanian',
  lv: 'Latvian',
  nb: 'Norwegian',
  nn: 'Norwegian',
  nl: 'Dutch',
  pl: 'Polish',
  pt: 'Portuguese',
  ro: 'Romanian',
  sk: 'Slovak',
  sl: 'Slovenian',
  sv: 'Swedish',
  zh: 'Mandarin',
};

export const DEFAULT_LOCALE = 'en-GB';

export const inactiveMarketCountries = ['HR'];

export const momentLocales = {
  'bg-BG': 'bg',
  'cs-CZ': 'cs',
  'da-DK': 'da',
  'de-AT': 'de-at',
  'de-CH': 'de-ch',
  'de-DE': 'de',
  'de-LU': 'de',
  'el-CY': 'el',
  'el-GR': 'el',
  'en-GB': 'en-gb',
  'en-IE': 'en-ie',
  'en-MT': 'en',
  'en-US': 'en',
  'es-CL': 'es',
  'es-CO': 'es',
  'es-ES': 'es',
  'et-EE': 'et',
  'fi-FI': 'fi',
  'fr-BE': 'fr',
  'fr-CH': 'fr-ch',
  'fr-FR': 'fr',
  'fr-LU': 'fr',
  'hr-HR': 'hr',
  'hu-HU': 'hu',
  'it-CH': 'it-ch',
  'it-IT': 'it',
  'lt-LT': 'lt',
  'lv-LV': 'lv',
  'nb-NO': 'nb',
  'nl-BE': 'nl-be',
  'nl-NL': 'nl',
  'pl-PL': 'pl',
  'pt-BR': 'pt-br',
  'pt-PT': 'pt',
  'ro-RO': 'ro',
  'sk-SK': 'sk',
  'sl-SI': 'sl',
  'sv-SE': 'sv',
};

export const defaultMomentLocale = 'en';
