import { find } from 'lodash/fp';

const getPageVisibilityApiProps = () => {
  if (typeof document.hidden !== 'undefined') {
    return { hiddenProp: 'hidden', visibilityChangeEvent: 'visibilitychange' };
  }
  const vendorPrefixes = ['webkit', 'ms', 'moz'];
  const vendorPrefix = find(
    (prefix) => typeof document[`${prefix}Hidden`] !== 'undefined',
    vendorPrefixes,
  );
  if (vendorPrefix) {
    return {
      hiddenProp: `${vendorPrefix}Hidden`,
      visibilityChangeEvent: `${vendorPrefix}visibilitychange`,
    };
  }
  return { hiddenProp: undefined, visibilityChangeEvent: undefined };
};

export const {
  hiddenProp,
  visibilityChangeEvent,
} = getPageVisibilityApiProps();

export const isPageVisibilitySupported = () => hiddenProp !== undefined;

export const isPageVisible = () =>
  !isPageVisibilitySupported() || !document[hiddenProp];
