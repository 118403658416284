import React, { Component } from 'react';
import { Global } from '@emotion/core';
import PropTypes from 'prop-types';
import getEnvFromUrl from 'services/get-env-from-url';
import withI18n from 'components/addI18n';
import { getLiveAgentConfig } from './constants/live-agent-config';
import { createLiveAgent, cleanUpLiveAgent } from './LiveAgentService';
import styles from './styles';

class LiveAgent extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  componentDidMount() {
    const { user } = this.props;
    const currentUrl = window.location.href;
    const env = getEnvFromUrl(currentUrl);
    const { CHAT_ID } = getLiveAgentConfig(env);

    createLiveAgent({ user, id: CHAT_ID });
  }

  // eslint-disable-next-line class-methods-use-this
  componentWillUnmount() {
    cleanUpLiveAgent();
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return <Global styles={styles} />;
  }
}

export default withI18n(LiveAgent);

LiveAgent.propTypes = {
  locale: PropTypes.string,
};
