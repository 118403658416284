import URIS from 'constants/uris';
import http from 'services/http';

/**
 * @description Sends a refund POST request to the backend and returns the
 *              response wrapped in a promise.
 */
// eslint-disable-next-line import/prefer-default-export
export function requestRefund({ transactionId, amount, password }) {
  return http.post(`${URIS.TRANSACTION_REFUND}/${transactionId}`, {
    password,
    amount,
  });
}
