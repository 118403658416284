export const injectScript = ({
  src,
  async = false,
  defer = false,
  id = null,
}) =>
  new Promise((resolve, reject) => {
    if (!src) {
      reject(new Error('No src provided for the script'));
    }
    const ref = document.querySelector(`script[src="${src}"]`);

    if (ref) {
      if (ref.getAttribute('data-loaded')) {
        resolve();
      } else {
        ref.onload = () => {
          ref.setAttribute('data-loaded', 'true');
          resolve();
        };
      }

      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = async;
    script.defer = defer;

    if (id) {
      script.id = id;
    }

    script.onerror = () => {
      script.setAttribute('data-loaded', 'false');
      reject(new Error(`Error loading script from "${src}"`));
    };

    script.onload = () => {
      script.setAttribute('data-loaded', 'true');
      resolve();
    };

    document.body.appendChild(script);
  });
