import URIS from 'constants/uris';
import http from 'services/http';

/**
 * @desc Fetches details for a given transaction.
 *
 * @param {string} transactionId - The ID of the transaction item.
 *
 * @return {object} The details data returned by the API.
 *
 */
getTransactionDetails.operation = 'READ';
getTransactionDetails.idFrom = 'ARGS';
export function getTransactionDetails(params) {
  return http
    .get(`${URIS.TRANSACTION_DETAILS}`, { params })
    .then(({ data }) => data);
}
