import URIS from 'constants/uris';
import http from 'services/http';

getProducts.operation = 'READ';
getProducts.idFrom = 'ARGS';
export function getProducts(page = 1, size = 9999) {
  return http
    .get(`${URIS.CATALOG}/products?page=${page}&size=${size}`)
    .then(({ data }) => data);
}

createProduct.operation = 'CREATE';
createProduct.invalidates = ['getProducts'];
export function createProduct(product) {
  return http
    .post(`${URIS.CATALOG}/products`, product)
    .then(({ data }) => data);
}

updateProduct.operation = 'UPDATE';
updateProduct.invalidates = ['getProducts'];
export function updateProduct(product) {
  return http.put(`${URIS.CATALOG}/products/${product.id}`, product);
}

deleteProduct.operation = 'DELETE';
deleteProduct.invalidates = ['getProducts'];
export function deleteProduct(id) {
  return http.delete(`${URIS.CATALOG}/products/${id}`);
}

export function getUploadUrl(merchantCode) {
  return http.get(`/api/pos/catalogs/${merchantCode}/import/upload_url`);
}

triggerImport.invalidates = ['getProducts'];
export function triggerImport(merchantCode, importId, fileName) {
  return http.put(`/api/pos/catalogs/${merchantCode}/import/${importId}/file`, {
    fileName,
  });
}

export function getImportStatus(merchantCode, importId) {
  return http.get(`/api/pos/catalogs/${merchantCode}/import/${importId}`);
}
