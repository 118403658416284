import React, { Fragment, Suspense, lazy } from 'react';
import { css } from '@emotion/core';
import { Router, Redirect } from '@reach/router';

import withSessionInfo from 'components/withSessionInfo';
import withOneTimeToken from 'components/withOneTimeToken';
import NotFound from 'pages/NotFound';
import { isProtectedRoute } from 'services/auth';

import ROUTES from './RoutesConstants';

// eslint-disable-next-line react/display-name
export const createRoute = (Component) => (props) => (
  <Suspense fallback>
    <Component {...props} />
  </Suspense>
);

const LoginRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "login" */ 'pages/Login')),
);

const OverviewRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "overview" */ 'pages/Overview')),
);

const SalesRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "sales" */ 'pages/Sales')),
);

const VerificationRoute = createRoute(
  lazy(() =>
    import(/* webpackChunkName: "verfication" */ 'pages/Verification'),
  ),
);

const CardRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "card" */ 'pages/Card')),
);

const ResetPasswordRoute = createRoute(
  lazy(() =>
    import(/* webpackChunkName: "reset-password" */ 'pages/ResetPassword'),
  ),
);

const SupportRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "support" */ 'pages/Support')),
);

const ReadRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "support" */ 'pages/Support')),
);

const SettingsRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "settings" */ 'pages/Settings')),
);

const DevelopersRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "developers" */ 'pages/Developers')),
);

const VirtualTerminalRoute = createRoute(
  lazy(() =>
    import(/* webpackChunkName: "virtual-terminal" */ 'pages/VirtualTerminal'),
  ),
);

const ActivationRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "activation" */ 'pages/Activation')),
);

const EmployeesRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "employees" */ 'pages/Employees')),
);

const DevicesRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "devices" */ 'pages/Devices')),
);

const GdprRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "gdpr" */ 'pages/GDPR')),
);

const CatalogRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "catalog" */ 'pages/Catalog')),
);

const CreateAccountAppsRedirectRoute = createRoute(
  lazy(() =>
    import(
      // eslint-disable-next-line max-len
      /* webpackChunkName: "create-account-apps-redirect" */ 'pages/RedirectAppsCreateAccount'
    ),
  ),
);

const ReferralsRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "referrals" */ 'pages/Referrals')),
);

const AccountRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "account-new" */ 'pages/Account')),
);

const EcomLiteRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "ecomlite" */ 'pages/EcomLite')),
);

const EcomLiteUpgradeRoute = createRoute(
  lazy(() =>
    import(/* webpackChunkName: "ecomlite" */ 'pages/EcomLiteUpgrade'),
  ),
);

const EcomLiteOrdersRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "orders" */ 'pages/Orders')),
);

const AccountingRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "accounting" */ 'pages/Accounting')),
);

const InvoicingRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "invoicing" */ 'pages/Invoicing')),
);

const InvoicingActivationRoute = createRoute(
  lazy(() =>
    import(
      /* webpackChunkName: "invoicing-activation" */ 'pages/InvoicingActivation'
    ),
  ),
);

const InvoicingForItalyRoute = createRoute(
  lazy(() =>
    import(/* webpackChunkName: "invoicing-italy" */ 'pages/InvoicingItaly'),
  ),
);

const ReceiptsRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "receipts" */ 'pages/Receipts')),
);

const LendingRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "lending" */ 'pages/Lending')),
);

const FiscalizationRoute = createRoute(
  lazy(() =>
    import(/* webpackChunkName: "fiscalization" */ 'pages/Fiscalization'),
  ),
);

const ReportsRoute = createRoute(
  lazy(() => import(/* webpackChunkName: "reports" */ 'pages/Reports')),
);

const SubscriptionsRoute = createRoute(
  lazy(() => import('pages/Subscriptions')),
);

const ParentRoute = ({ children }) => <Fragment>{children}</Fragment>;

const getRouteProps = (prefix, route) => ({
  key: prefix + route,
  path: prefix + route,
});

const getRedirectUrl = ({ shopUrl, pathname, search, otp }) => {
  const redirectUrl = `${shopUrl}${pathname}${search}`;

  if (isProtectedRoute(redirectUrl)) {
    return `${redirectUrl}${search ? '&' : '?'}c=${otp}`;
  }

  return redirectUrl;
};

const ShopRedirection = ({ sessionInfo, otp }) => {
  const { shop_url: shopUrl } = sessionInfo;
  const { search, pathname } = window.location;

  const redirectUrl = getRedirectUrl({
    shopUrl,
    pathname,
    search,
    otp,
  });

  window.location.assign(redirectUrl);

  return null;
};

const RedirectToShop = withSessionInfo(withOneTimeToken(ShopRedirection));

/**
 * Reach router doesn't support Fragments as direct children of the Router,
 * but it does accept array as children.
 * https://github.com/reach/router/pull/184
 */

/* eslint-disable react/jsx-key */
const getBaseRoutes = (prefix) => [
  <Redirect
    key={prefix + ROUTES.START}
    noThrow
    from={prefix + ROUTES.START}
    to={`${prefix}${ROUTES.SIGNUP_CREATE}`}
  />,
  <Redirect
    key={prefix + ROUTES.OVERVIEW}
    noThrow
    from={prefix}
    to={prefix + ROUTES.OVERVIEW}
  />,
  <Redirect
    key={prefix + ROUTES.LOGOUT}
    noThrow
    from={prefix + ROUTES.LOGOUT}
    to={prefix + ROUTES.LOGIN}
  />,
  <Redirect
    key={`${prefix + ROUTES.RESET_PASSWORD_LEGACY}/:token`}
    noThrow
    from={`${prefix + ROUTES.RESET_PASSWORD_LEGACY}/:token`}
    to={`${prefix + ROUTES.RESET_PASSWORD}/:token`}
  />,
  <Redirect
    key={prefix + ROUTES.RESET_PASSWORD_LEGACY}
    noThrow
    from={prefix + ROUTES.RESET_PASSWORD_LEGACY}
    to={prefix + ROUTES.RESET_PASSWORD}
  />,
  <Redirect
    key={`${prefix}card-redirect`}
    noThrow
    to={prefix + ROUTES.CARD}
    from={prefix + ROUTES.CARD_OVERVIEW}
  />,
  <Redirect
    key={`${prefix}transactions`}
    noThrow
    to={`${prefix}/${ROUTES.SALES}/${ROUTES.TRANSACTIONS}`}
    from={`${prefix}transactionsnew`}
  />,
  <Redirect
    key={`${prefix}account`}
    noThrow
    to={prefix + ROUTES.ACCOUNT}
    from={`${prefix}account-new`}
  />,
  <Redirect
    key={`${prefix}account`}
    noThrow
    to={prefix + ROUTES.ACCOUNT}
    from={`${prefix}account/documents`}
  />,
  <AccountRoute {...getRouteProps(prefix, ROUTES.ACCOUNT)} />,
  <ActivationRoute {...getRouteProps(prefix, ROUTES.ACTIVATION)} />,
  <CardRoute {...getRouteProps(prefix, `${ROUTES.CARD}/*`)} />,
  <CatalogRoute {...getRouteProps(prefix, `${ROUTES.CATALOG}/*`)} />,
  <CreateAccountAppsRedirectRoute
    {...getRouteProps(prefix, ROUTES.CREATE_ACCOUNT_APPS)}
  />,
  <DevelopersRoute {...getRouteProps(prefix, ROUTES.DEVELOPERS)} />,
  <DevicesRoute {...getRouteProps(prefix, ROUTES.DEVICES)} />,
  <EmployeesRoute {...getRouteProps(prefix, ROUTES.EMPLOYEES)} />,
  <GdprRoute {...getRouteProps(prefix, ROUTES.GDPR)} />,
  <LoginRoute {...getRouteProps(prefix, ROUTES.LOGIN)} />,
  <OverviewRoute {...getRouteProps(prefix, ROUTES.OVERVIEW)} />,
  <ReferralsRoute {...getRouteProps(prefix, ROUTES.REFERRALS)} />,
  <ParentRoute {...getRouteProps(prefix, ROUTES.RESET_PASSWORD)}>
    <ResetPasswordRoute default path="/" />
    <ResetPasswordRoute path=":token" />
  </ParentRoute>,
  <SettingsRoute {...getRouteProps(prefix, ROUTES.SETTINGS)} />,
  <ParentRoute {...getRouteProps(prefix, ROUTES.SUPPORT)}>
    <SupportRoute default path="/" />
    <SupportRoute path="success" />
    <ReadRoute {...getRouteProps(prefix, `${ROUTES.READ}/*`)} />
  </ParentRoute>,
  <SalesRoute {...getRouteProps(prefix, `${ROUTES.SALES}/*`)} />,
  <VerificationRoute {...getRouteProps(prefix, `${ROUTES.VERIFICATION}/*`)} />,
  <VirtualTerminalRoute {...getRouteProps(prefix, ROUTES.VIRTUAL_TERMINAL)} />,
  <AccountingRoute {...getRouteProps(prefix, ROUTES.ACCOUNTING)} />,
  <InvoicingRoute {...getRouteProps(prefix, ROUTES.INVOICING)} />,
  <InvoicingActivationRoute
    {...getRouteProps(prefix, ROUTES.INVOICING_ACTIVATION)}
  />,
  <InvoicingForItalyRoute {...getRouteProps(prefix, ROUTES.INVOICING_ITALY)} />,
  <EcomLiteRoute {...getRouteProps(prefix, ROUTES.ECOMLITE)} />,
  <EcomLiteUpgradeRoute {...getRouteProps(prefix, ROUTES.ECOMLITE_UPGRADE)} />,
  <EcomLiteOrdersRoute {...getRouteProps(prefix, ROUTES.ECOMLITE_ORDERS)} />,
  <ReceiptsRoute {...getRouteProps(prefix, ROUTES.RECEIPTS)} />,
  <LendingRoute {...getRouteProps(prefix, `${ROUTES.LENDING}/*`)} />,
  <FiscalizationRoute
    {...getRouteProps(prefix, `${ROUTES.FISCALIZATION}/*`)}
  />,
  <SubscriptionsRoute
    {...getRouteProps(prefix, `${ROUTES.SUBSCRIPTIONS}/*`)}
  />,
  <ReportsRoute {...getRouteProps(prefix, ROUTES.REPORTS)} />,
  // Routes in the Shop domain
  <RedirectToShop {...getRouteProps(prefix, ROUTES.SIGNUP_CREATE)} />,
  <RedirectToShop {...getRouteProps(prefix, ROUTES.SIGNUP_SHOP)} />,
  <RedirectToShop {...getRouteProps(prefix, ROUTES.SHOP)} />,
];

const Routes = () => (
  <div
    css={css`
      width: 100%;
    `}
  >
    <Router>
      <NotFound default />

      {getBaseRoutes('/')}
      {getBaseRoutes('/:locale-slug/')}
    </Router>
  </div>
);

export default Routes;
