import URIS from 'constants/uris';
import http from 'services/http';

getRegistration.operation = 'READ';
getRegistration.idFrom = () => 'getRegistrationId';
export function getRegistration() {
  return http.get(URIS.REGISTRATIONS);
}

updateRegistration.invalidates = ['getRegistration'];
export function updateRegistration(params) {
  return http.put(URIS.REGISTRATIONS, params);
}
