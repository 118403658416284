import { identity } from 'lodash/fp';
import axios from 'axios';
import { getDeviceFingerPrint } from 'services/antifraud';

import { isProtectedRoute, redirectToLogin } from 'services/auth';

const UNAUTHORIZED_STATUS = 401;

export const handleHttpError = ({ response = {} } = {}) => {
  const { status } = response;

  const isAuthError = status === UNAUTHORIZED_STATUS;
  const shouldRedirect = isProtectedRoute(window.location.pathname);

  if (isAuthError && shouldRedirect) {
    return redirectToLogin();
  }

  return Promise.reject(response);
};

axios.interceptors.request.use((config) => {
  const deviceFingerPrint = getDeviceFingerPrint();
  if (deviceFingerPrint) {
    config.headers['X-Device-Fingerprint'] = deviceFingerPrint;
  }

  return config;
});

axios.interceptors.response.use(identity, handleHttpError);

export default axios;
