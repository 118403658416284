import React from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import {
  NotificationList,
  NotificationBanner,
  Heading,
  Notification as CircuitNotification,
} from '@sumup/circuit-ui';
import styled from '@emotion/styled';

import withI18n from 'components/addI18n';

export const TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.mq.mega} {
      display: flex;
    }
  `};
`;

const StyledAction = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.mega};

    ${theme.mq.mega} {
      align-self: center;
      flex-shrink: 0;
      margin-top: 0;
      margin-left: auto;
      padding-left: ${theme.spacings.mega};
    }
  `};
`;

const Notification = ({
  type,
  dismissable,
  onRemove,
  title,
  children,
  action,
  full,
  translate: t,
  icon,
}) => {
  const NotificationVariant = full ? NotificationBanner : NotificationList;
  return (
    <NotificationVariant>
      <CircuitNotification
        icon={icon}
        variant={type}
        onClose={dismissable ? onRemove : undefined}
        closeLabel={dismissable ? t('common.close') : null}
        css={css`
          flex-grow: 1;
        `}
      >
        <StyledWrapper>
          <div>
            {title && <Heading size="kilo">{title}</Heading>}

            {children}
          </div>

          {action && <StyledAction>{action}</StyledAction>}
        </StyledWrapper>
      </CircuitNotification>
    </NotificationVariant>
  );
};

Notification.propTypes = {
  onRemove: PropTypes.func,
  type: PropTypes.oneOf([TYPES.SUCCESS, TYPES.ERROR, TYPES.WARNING]),
  dismissable: PropTypes.bool,
  title: PropTypes.string,
  full: PropTypes.bool,
  action: PropTypes.any,
  icon: PropTypes.element,
  translate: PropTypes.func.isRequired,
};

Notification.defaultProps = {
  dismissable: true,
  translate: () => {},
};

export default withI18n(Notification);
