import { __, find, includes } from 'lodash/fp';

import ROUTES from 'components/Routes/RoutesConstants';

const NO_HEADER_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.RESET_PASSWORD,
  ROUTES.RESET_PASSWORD_LEGACY,
  ROUTES.LOGOUT,
  ROUTES.SIGNUP_CREATE,
  ROUTES.CREATE_ACCOUNT_APPS,
  ROUTES.START,
  ROUTES.SIGNUP_SHOP,
];

export const hasHeader = (route) =>
  !find(includes(__, route), NO_HEADER_ROUTES);
