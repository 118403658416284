import { uniqueId } from 'lodash/fp';

import URIS from 'constants/uris';
import http from 'services/http';

areReportsAvailable.operation = 'READ';
areReportsAvailable.idFrom = uniqueId;
export function areReportsAvailable() {
  return http.get(URIS.REPORTS_AVAILABLE);
}

areReportsAvailable.operation = 'READ';
areReportsAvailable.idFrom = uniqueId;
export function getAvailableTaxReports() {
  return http.get(URIS.TAX_REPORT);
}
