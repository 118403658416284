import React, { Component } from 'react';
import { hoistStatics, wrapDisplayName } from 'recompose';

import api from 'api';

const withSessionInfo = (WrappedComponent) =>
  class extends Component {
    WrappedComponent = WrappedComponent;

    static propTypes = WrappedComponent.propTypes;

    displayName = wrapDisplayName(WrappedComponent, 'withSessionInfo');

    state = {
      sessionInfo: null,
    };

    componentDidMount() {
      api.sessionInfo
        .getSessionInfo()
        .then((sessionInfo) => this.setState({ sessionInfo }));
    }

    render() {
      const { sessionInfo } = this.state;

      if (!sessionInfo) {
        return null;
      }

      return <WrappedComponent {...this.props} sessionInfo={sessionInfo} />;
    }
  };

export default hoistStatics(withSessionInfo);
