import Cookie from 'js-cookie';
import { __, find, get, includes, isEmpty } from 'lodash/fp';
import qs from 'query-string';
import { navigate } from '@reach/router';

import api from 'api';
import { ROUTES } from 'components/Routes';
import {
  addLocale,
  getLocale,
  removeLocale,
  isValidRoute,
} from 'components/Link';

import { UNPROTECTED_ROUTES } from './unprotected-routes';

// FIXME: Replace this when we either move to webpack4 or refactor Employees page
// import { ROUTES } from 'components/Routes';

export const SESSION_KEY = 'XSRF-TOKEN';

/**
 * web-backend does not respond with an error on the oauth endpoint
 * when the credentials are wrong. Instead, it sends you a
 * LOGIN_SUCCESSFUL status in the response body, when they are right.
 * To standarize error behavior in the frontend, we create an error
 * with the response status from the body as error message.
 */
const mapAuthResponse = (response) => {
  const status = get('data.status', response);
  const message = status === 'AUTHORIZED' ? 'INVALID_CREDENTIALS' : status;
  if (message !== 'LOGIN_SUCCESSFUL') {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(new Error(message));
  }
  return response;
};

/**
 * There is one error which seems to get forwarded from the API
 * gateway by web-backend as an error response. This has a code of
 * NOT_AUTHORIZED in the response body. We map this to an Error
 * with a corresponding error message.
 */
const mapAuthError = (err) => {
  const errorCode = get('data.error_code', err);
  return Promise.reject(new Error(errorCode));
};

export const hasSession = () => Cookie.get(SESSION_KEY);

export const authenticate = (...params) =>
  api.auth
    .authenticate(...params)
    .catch(mapAuthError)
    .then(mapAuthResponse)
    .then(api.sessionInfo.getSessionInfo);

export const logout = () =>
  new Promise((resolve) => {
    const requestingLogout = api.logout.logout();
    Cookie.remove(SESSION_KEY);
    resolve(requestingLogout);
  });

export const createUnauthorizedSession = () => {
  Cookie.remove(SESSION_KEY);
  return api.sessionInfo
    .updateSessionInfo()
    .then(api.sessionInfo.getSessionInfo);
};

export const getOtpToken = (url) => qs.parse(url).c;

export const createOtpSession = (token) => {
  Cookie.remove(SESSION_KEY);
  return api.sessionInfo.getSessionInfo({ token });
};

export const isProtectedRoute = (route) =>
  !find(includes(__, route), UNPROTECTED_ROUTES);

const getCurrentRoute = () => window.location.pathname.slice(1);

const buildQueryParams = (query, currentRoute) => {
  const params = qs.parse(query);

  // We don't need to add ?redirect when the route is overview
  return currentRoute && !includes(ROUTES.OVERVIEW, currentRoute)
    ? {
        ...params,
        redirect: removeLocale(currentRoute).replace(/^\//, ''),
      }
    : params;
};

const getQueryString = (currentRoute) => {
  const params = buildQueryParams(window.location.search, currentRoute);
  return isEmpty(params) ? '' : `?${qs.stringify(params)}`;
};

export const redirectToLogin = () => {
  const currentRoute = getCurrentRoute();
  const query = getQueryString(currentRoute);

  const locale = getLocale(currentRoute);
  const target = addLocale(locale, `/${ROUTES.LOGIN}${query}`);

  if (!currentRoute || isValidRoute(currentRoute)) {
    navigate(target);
    return;
  }

  window.location.assign(target);
};
