import URIS from 'constants/uris';
import http from 'services/http';
import { toEditableProfile } from './merchant-profile';

getPersonalProfile.operation = 'READ';
export function getPersonalProfile() {
  return http.get(URIS.PERSONAL_PROFILE);
}

getPersonalProfileForChange.operation = 'READ';
export function getPersonalProfileForChange() {
  return http.get(toEditableProfile(URIS.PERSONAL_PROFILE));
}

updatePersonalProfile.invalidates = ['getPersonalProfile'];
export function updatePersonalProfile(params) {
  return http.put(URIS.PERSONAL_PROFILE, params);
}

createPersonalProfile.invalidates = [
  'getPersonalProfile',
  'getPersonalProfileForChange',
];
export function createPersonalProfile(params) {
  return http.put(toEditableProfile(URIS.PERSONAL_PROFILE), params);
}

updatePersonalProfileDraft.invalidates = [
  'getPersonalProfile',
  'getPersonalProfileForChange',
];
export function updatePersonalProfileDraft(params) {
  return http.post(URIS.PERSONAL_PROFILE_DRAFT, params);
}
